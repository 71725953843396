export const FAQ_LIST = [
  {
    id: '1',
    question: 'За какой минимальный срок необходимо заказывать торт?',
    answer: (
      <p>
        Минимальный срок заказа – от 7 дней для торта с лаконичным дизайном.
        <br/>
        От 2 недель и более для тортов со сложным декором.
        <br/>
        Для того, чтобы мы могли выделить достаточное время для изготовления вашего торта мы просим размещать заказы за три недели для торта с лаконичным дизайном. Более оригинальные заказы потребуют от 6 недель.
        <br/>
        Как правило, линейка заказов в нашем ателье полностью сформирована на 2-3 месяца вперед, а свадебные торты часто заказывают за 6 месяцев до даты торжества.
      </p>
    )
  },
  {
    id: '2',
    question: 'Какая информация необходима для того, чтобы сделать заказ?',
    answer: (
      <>
        <p>
          При обращении в Cake Buro, в преддверии личной встречи с персональным менеджером, каждому клиенту мы задаем список из семи базовых вопросов:
        </p>
        <ul>
          <li>•	Какое важное событие вы празднуете?</li>
          <li>•	Дата праздника?</li>
          <li>•	Количество гостей?</li>
          <li>•	Место проведения праздника?</li>
          <li>•	Какова будет флористика и/или цветовая гамма декора?</li>
          <li>•	Есть ли особые элементы декора, которые вам очень дороги в рамках этого праздника?</li>
          <li>•	Есть ли определенный бюджет на торт, рамок которого нам необходимо придерживаться?</li>
        </ul>
      </>
    ),
  },
  {
    id: '3',
    question: 'Сколько стоит ваш торт?',
    answer: (
      <p>
        Есть минимальная стоимость за порцию - от 500 рублей. А дальше все зависит от сложности дизайна, сроков исполнения и вашего воображения. Наши возможности практически безграничны.
        <br/>
        В Cake Buro не существует готовой коллекции тортов. Каждый дизайн создается индивидуально для вашего праздника. И чем более сложный дизайн, тем больше времени нашей команды потребует его реализация.
        <br/>
        При проработке эскиза мы оцениваем количество рабочих часов, которое потребует именно ваш торт. Из этого и складывается его финальная стоимость. И зачастую большой лаконичный торт может стоит дешевле небольшого, но богато украшенного деталями.
      </p>
    )
  },
  {
    id: '4',
    question: 'Мы очень хотим заказать ваш торт на свадьбу, но церемония будет проходить в другом городе/стране. Что делать?',
    answer: (
      <p>
        Современный мир невероятно огромный, но и невероятно маленький. Мы будем рады изготовить торт и помочь организовать его доставку в нужное для вас место и время. Но, конечно, как для организации неординарного праздника, так и для организации доставки торта к его кульминации, всегда требуется время, а иногда – частный самолёт.
      </p>
    )
  },
  {
    id: '5',
    question: 'Могу я заказать торт на день рождения к завтрашнему утру?',
    answer: (
      <p>
        Мы постараемся придумать, как вам помочь. Но, к сожалению, это возможно далеко не всегда.
        <br/>
        У нас нет готовых дизайнов тортов. Все торты создаются под конкретного клиента и конкретный праздник.
      </p>
    )
  },
  {
    id: '6',
    question: 'Какова стоимость доставки торта?',
    answer: (
      <>
        <p>
          Стоимость доставки складывается из разных факторов:
        </p>
        <ul>
          <li>•	Сложность дизайна торта</li>
          <li>•	Местоположение торжества</li>
          <li>•	Выезд кондитера</li>
        </ul>
        <p>
          Небольшой торт приятно и легко забрать из ближайшего ресторана «Кофемания» самостоятельно. В случае, если это не входит в ваши планы, мы будем рады организовать доставку.
          <br/>
          Если вы планируете выездное мероприятие за пределами Москвы, водитель с машиной-холодильник привезет торт на площадку, где мы с организаторами детально разработаем план по хранению торта в идеальных условиях до момента подачи.
          <br/>
          Если вы планируете праздник за границей, мы постараемся помочь организовать доставку торта. Но на это требуется время, а иногда – частный самолёт.
        </p>
      </>
    )
  },
  {
    id: '7',
    question: 'Что если я не могу приехать в ателье, чтобы пообщаться с личным менеджером и провести дегустацию?',
    answer: (
      <p>
        При предварительной договоренности, мы будем рады организовать выездную встречу и дегустацию. Или отправить дегустационный сет с описаниями начинок для персональной дегустации по удобному вам адресу
        <br/>
        В случае, если вы проживаете далеко от Москвы и доверяете нашему вкусу, мы будем рады обсудить детали будущего торта по телефону, мессенджеру или электронной почте и предложить вам несколько вариантов наиболее гармоничных и выигрышных вкусовых сочетаний по вашему вкусу.
      </p>
    )
  },
  {
    id: '8',
    question: 'У меня есть любимый торт из детства, который всегда пекла моя бабушка. Можно сделать такой же торт на мой праздник в вашем ателье?',
    answer: (
      <p>
        Рецепты бабушек и гастрономические воспоминания из детства — это святое! Если вы доверите нам рецепт, мы будем рады воссоздать его вкус в одном из наших тортов. Но полностью повторить вид и текстуру рецепта в торте для особого случая не всегда возможно.
        <br/>
        Впрочем, мы всегда с достоинством принимаем любой кулинарный вызов. И очень уважаем любимых бабушек.
      </p>
    )
  },
  {
    id: '9',
    question: 'Что если я люблю вкусы, которых нет в вашей стандартной дегустации?',
    answer: (
      <p>
        Если вы понимаете, что хотели бы видеть некую комбинацию вкусов, которых нет в стандартной дегустации, пожалуйста дайте нам знать до или во время встречи.
        <br/>
        Мы будем рады дополнить нашу вкусовую палитру и, по необходимости, провести повторную дегустацию именно той вкусовой комбинации, которую вы мечтаете попробовать и реализовать в торте.
      </p>
    )
  },
  {
    id: '10',
    question: 'Сколько разных вкусовых комбинаций вы рекомендуете подавать гостям?',
    answer: (
      <p>
        Мы не рекомендуем сочетать более двух вкусовых комбинаций в торте для 20-50 гостей, и более трех в торте для 50 и более гостей.
      </p>
    )
  },
  {
    id: '11',
    question: 'Есть ли у вас готовый каталог? ',
    answer: (
      <p>
        В Cake Buro нет готового каталога тортов. Мы будем рад показать вам портфолио предыдущих работ и интегрировать понравившиеся вам элементы и решения в дизайн торта для вашего праздника
      </p>
    )
  },
  {
    id: '12',
    question: 'Я видела в журнале ваш свадебный торт на 300 человек. На моей свадьбе будет 45 человек гостей. Можете ли вы воссоздать торт из журнала в виде его уменьшенной копии?',
    answer: (
      <p>
        В Cake Buro мы всегда рады создать для вас дизайн торта именно для вашего праздника. Не всегда есть техническая возможность полностью воссоздать дизайн большого торта в его уменьшенной копии. Тем не менее, всегда есть возможность использовать подобную цветовую гамму и элементы декора для создания идентичного стиля и эффекта.
      </p>
    )
  },
  {
    id: '13',
    question: 'Могу ли я купить фигурки и свадебные цветы отдельно от вашего торта?',
    answer: (
      <p>
        В Cake Buro мы не продаем элементы декора отдельно от наших тортов.
      </p>
    )
  },
  {
    id: '14',
    question: 'Когда к вам можно прийти на дегустацию? Работаете ли вы вечером/в выходные/в праздники?',
    answer: (
      <p>
        Мы будем рады организовать встречу с личным менеджером и дегустацию торта в удобный для вас день и время по предварительной договоренности.
      </p>
    )
  },
]

export default FAQ_LIST
