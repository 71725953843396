import { useState, useRef, useEffect, useMemo } from 'react'
import styled, { css, createGlobalStyle } from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import Modal from 'react-modal'
import { FormModal } from '../../components'
import { ProductFormModal } from '../../components'
import LazyLoad from 'react-lazyload'
import { horizontalScale, mobileMediaQuery } from 'utils/helpers'
import { ReactComponent as CancelSVG } from 'assets/svg/cancel.svg'
import { ReactComponent as LogoSVG } from 'assets/svg/logo.svg'
import { ReactComponent as NextArrowSVG } from 'assets/svg/next_arrow.svg'
import { ReactComponent as DiamondSVG } from 'assets/svg/diamond.svg'
import COLLECTION_LIST from './mockup'

const Collections = () => {
  const swiperRef = useRef<any>(null)

  const [selectedCollectionId, setSelectedCollectionId] = useState<string | undefined>(undefined)

  const [isFormOpen, setFormOpen] = useState(false)

  const [isProductFormOpen, setProductFormOpen] = useState(false)
 
  const onPressPrev = () => swiperRef.current?.slidePrev()

  const onPressNext = () => swiperRef.current?.slideNext()

  const selectedCollection = useMemo(() => (
    COLLECTION_LIST.find(({ id }) => id === selectedCollectionId)
  ), [selectedCollectionId])

  const formModalClose = () => setFormOpen(false)

  const productFormModalClose = () => {
    setProductFormOpen(false)
  }

  const productModalClose = () => {
    setProductFormOpen(false)
    closeCollection()
  }

  const openCollection = (id: string) => {
    setSelectedCollectionId(id)
   const url = '?collection=' + id
    window.history.pushState(url, '', url)
  }

  const closeCollection = () => {
    setSelectedCollectionId(undefined)
    const url = '/'
    window.history.pushState('', '', url)
  }

  const checkUrlParams = () => {
    if(window.location && window.location?.search?.includes('collection')) {
      const collection = new URLSearchParams(window.location.search).get('collection')
      setSelectedCollectionId(collection?.toString())
    } else {
      setSelectedCollectionId(undefined)
    }
  }

  const onBackOrForwardButtonEvent = (e: Event) => {
    checkUrlParams()
  }

  useEffect(() => {
    window.onpopstate = onBackOrForwardButtonEvent
    checkUrlParams()
  }, [window.location?.search])

  return (
    <Section id='collections'>
      <Title>Коллекции</Title>
      <Description>
      Каждый праздник – особенный. Ваш торт станет главным украшением праздника и надолго запомнится вам и вашим гостям.
      </Description>
      <SwiperContainer
        onSwiper={(swiper) => { swiperRef.current = swiper }}
        slidesPerView={3}
        breakpoints={{
          320: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          769: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }}
      >
      {COLLECTION_LIST.map((i) => (
        i.isShow &&
        <SwiperSlide key={`collections-item-${i.id}`} className={`collections-item-${i.id}`}>
          <CollectionItem onClick={() => openCollection(i.id)}>
            <CollectionItemImage
              src={i.src}
              alt={`${i.description} ${i.description}`}
              isWide={i.isWide}
            />
            <CollectionItemTitle isWide={i.isWide}>{i.title}</CollectionItemTitle>
            <CollectionItemDescription>{i.description}</CollectionItemDescription>
          </CollectionItem>
        </SwiperSlide>
      ))}
      </SwiperContainer>
      <ButtonsBlock>
        <NavButton onClick={onPressPrev}>
          <PrevArrowIcon />
        </NavButton>
        <NavButton onClick={onPressNext}>
          <NextArrowIcon />
        </NavButton>
      </ButtonsBlock>
      <CollectionWrapper>
        {COLLECTION_LIST.map((i) => (
          i.isShow &&
          <CollectionItem
            key={`collections-mobile-item-${i.id}`}
            onClick={() => openCollection(i.id)}
          >
            <CollectionItemImage
              src={i.src}
              alt={`${i.description} ${i.description}`}
              isWide={i.isWide}
            />
            <CollectionItemTitle isWide={i.isWide}>{i.title}</CollectionItemTitle>
            <CollectionItemDescription>{i.description}</CollectionItemDescription>
          </CollectionItem>
        ))}
        <FormButtonWrapper>
          <FormButton onClick={() => setFormOpen(true)}>
            <DiamondIcon />
              <span>
                Оставить заявку
              </span>
            <DiamondIcon />
          </FormButton>
        </FormButtonWrapper>
      </CollectionWrapper>
      
      <Modal
        isOpen={Boolean(selectedCollectionId)}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeCollection}
        className='gallery-modal'
        overlayClassName='gallery-modal-overlay'
      >
        <ModalContainer>
          <ModalMobileHeader>
            <LogoIcon />
            <CancelIcon onClick={closeCollection} />
          </ModalMobileHeader>
          <ModalHeader>
            <ModalTitle>{selectedCollection?.description || selectedCollection?.title}</ModalTitle>
            <CancelIcon onClick={closeCollection} />
          </ModalHeader>
          <ModalDescription>
            В галерее представлены работы наших мастеров. Каждый день мы воплощаем новые идеи наших клиентов.
          </ModalDescription>
          
          {selectedCollection?.direct === 'column' ? (
            <MasonryGalleryWrapper>
              <MasonryGallery>
                {selectedCollection?.images.map(( image ) => (
                  <div key={image.src}>
                    <GalleryImage
                      src={`${process.env.PUBLIC_URL}${image.src}`}
                      alt={image.src}
                      onClick={() => setProductFormOpen(true)}
                    />
                    {image.title && 
                      <TitleImage onClick={() => setProductFormOpen(true)}>
                        {image.title ?? ''}
                      </TitleImage>
                    }
                  </div>
                ))}
              </MasonryGallery>
            </MasonryGalleryWrapper>
          ) : (
            <ModalItemsGalleryWrapper>
              <ModalItemsGallery>
                {selectedCollection?.images.map(( image ) => (
                  <>
                    <LazyLoad
                      key={image.src}
                      offset={500}
                      once
                    >
                      <GalleryImage
                        src={`${process.env.PUBLIC_URL}${image.src}`}
                        alt={image.src}
                        onClick={() => setProductFormOpen(true)}
                      />
                      {image.title && 
                      <TitleImage onClick={() => setProductFormOpen(true)}>
                        {image.title ?? ''}
                      </TitleImage>
                      } 
                    </LazyLoad>
                  </>
                ))}
              </ModalItemsGallery>
            </ModalItemsGalleryWrapper>
          )}
        </ModalContainer>
        <FormButtonWrapper isSticky={true}>
          <FormButton onClick={() => setFormOpen(true)}>
            <DiamondIcon />
              <span>
                Оставить заявку
              </span>
            <DiamondIcon />
          </FormButton>
        </FormButtonWrapper>
      </Modal>

      <FormModal
        isOpen={isFormOpen}
        onRequestClose={formModalClose}
      />

      <ProductFormModal
        isOpen={isProductFormOpen}
        onRequestClose={productModalClose}
        onRequestBack={productFormModalClose}
      />

    </Section>
  )
}

export default Collections

// Import GlobalStyle at the top of React tree
export const GlobalStyle = createGlobalStyle`
  .ReactModal__Html--open,
  .ReactModal__Body--open {
    overflow: hidden; /* prevents background page from scrolling when the modal is open */
  }

  .gallery-modal-overlay,
  .form-modal-overlay,
  .form-success-modal-overlay {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    min-height: -webkit-fill-available;
    background: rgba(114, 114, 114, 0.55);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gallery-modal {
    background: #ffffff;
    padding: 0;
    border: none;
    width: ${horizontalScale(1694)};
    height: ${horizontalScale(1044)};
    max-height: calc(100% - 100px);
    margin: auto;
    overflow-y: auto;
  }

  .form-modal {
    background: #ffffff;
    padding: 0;
    border: none;
    width: ${horizontalScale(1694)};
    height: ${horizontalScale(1044)};
    max-height: calc(100% - 100px);
    margin: auto;
    overflow-y: auto;
  }

  .form-success-modal {
    background: #ffffff;
    padding: 0;
    border: none;
    width: ${horizontalScale(640)};
    height: ${horizontalScale(240)};
    margin: auto;
    overflow-y: auto;
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    .gallery-modal {
      width: 100%;
      height: 100%;
      max-height: 100%;
    }

    .form-modal {
      width: 100%;
      height: 100%;
      max-height: 100%;
    }

    .form-success-modal {
      width: 100%;
      height: ${mobileScale(157)};
      margin-top: auto;
      margin-bottom: auto;
    }
  `)}
`

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(0deg, #F9F9F9, #F9F9F9), #262615;
  padding: ${horizontalScale(160)} ${horizontalScale(70)};

  ${mobileMediaQuery((css, mobileScale) => css`
    flex-direction: column;
    position: relative;
    padding: ${mobileScale(64)} ${mobileScale(16)};
  `)}
`

const Title = styled.h2`
  font-size: ${horizontalScale(48)};
  line-height: ${horizontalScale(58)};
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #98734F;
  text-align: left;
  margin-bottom: ${horizontalScale(40)};

  ${mobileMediaQuery((css, mobileScale) => css`
    font-weight: 500;
    font-size: ${mobileScale(18)};
    line-height: ${mobileScale(22)};
    margin-top: 0;
    margin-bottom: ${mobileScale(16)};
  `)}
`

const TitleImage = styled.h2`
  font-family: 'TT Commons';  
  font-size: ${horizontalScale(24)};
  line-height: ${horizontalScale(28)};
  font-weight: 500;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #170000;
  text-align: left;
  margin-left: ${horizontalScale(45)};
  margin-top: ${horizontalScale(44)};
  margin-bottom: ${horizontalScale(44)};

  ${mobileMediaQuery((css, mobileScale) => css`
    
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(18)};
    margin-left: 0;
    margin-top: ${mobileScale(20)};
    margin-bottom: ${mobileScale(20)};
  `)}
`

const Description = styled.span`
  font-size: ${horizontalScale(24)};
  line-height: ${horizontalScale(29)};
  max-width: ${horizontalScale(860)};
  letter-spacing: 0.025em;
  text-align: left;
  margin-bottom: ${horizontalScale(73)};

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(17)};
    margin-bottom: ${mobileScale(32)};
    max-width: ${mobileScale(280)};
  `)}
`

const SwiperContainer = styled(Swiper)`
  display: none;
  width: 100%;
  height: ${horizontalScale(635)};
  margin-bottom: ${horizontalScale(40)};

  .swiper-slide {
    height: ${horizontalScale(443)};
    position: relative;
  }

  ${mobileMediaQuery((css) => css`
    display: none;

    .swiper-slide {
      display: none;
    }
  `)}
`

const CollectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
  
  ${mobileMediaQuery((css, mobileScale) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
  `)}
  `

const CollectionItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
  margin-bottom: ${horizontalScale(117)};
  
  ${mobileMediaQuery((css, mobileScale) => css`
    &:not(:nth-last-child(-n + 2)) {
      margin-bottom: ${mobileScale(30)};
    }
  `)}
`

const CollectionItemImage = styled.img<{ isWide: boolean }>`
  width: ${props => horizontalScale(props.isWide ? 869 : 422)};
  height: ${horizontalScale(550)};
  margin-bottom: ${horizontalScale(16)};
  object-fit: cover;

  ${props => mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(props.isWide ? 288 : 136)};
    height: ${mobileScale(props.isWide ? 182 : 154)};
    margin-bottom: ${mobileScale(props.isWide ? 0 : 8)};
  `)}
`

const CollectionItemTitle = styled.span<{ isWide: boolean }>`
  font-weight: 500;
  font-size: ${horizontalScale(15)};
  line-height: ${horizontalScale(17)};
  margin-bottom: ${horizontalScale(16)};
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #170000;

  ${props => props.isWide && css`
    text-transform: none;
    position: absolute;
    top: ${horizontalScale(456)};
    left: ${horizontalScale(46)};
    font-weight: 600;
    font-size: ${horizontalScale(32)};
    line-height: ${horizontalScale(38)};
  `}

  ${props => mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(8)};
    line-height: ${mobileScale(10)};
    margin-bottom: ${mobileScale(4)};

    ${(() => props.isWide && css`
      font-size: ${mobileScale(14)};
      line-height: ${mobileScale(17)};
      top: ${mobileScale(152)};
      left: ${mobileScale(16)};
    `)()}
  `)}
`

const CollectionItemDescription = styled.span`
  font-weight: 500;
  font-size: ${horizontalScale(24)};
  line-height: ${horizontalScale(28)};
  text-transform: uppercase;
  color: #170000;

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(16)};
    max-width: 80%;
    text-align: left;
  `)}
`

const ModalContainer = styled.div`
  padding: ${horizontalScale(80)} ${horizontalScale(120)} 0;

  ${mobileMediaQuery((css, mobileScale) => css`
    padding: 0;
    padding-top: ${mobileScale(78)};
  `)}
`

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  ${mobileMediaQuery((css, mobileScale) => css`
    padding: 0 ${mobileScale(16)};
    svg {
      display: none;
    }
  `)}
`

const ModalMobileHeader = styled.div`
  display: none;
  
  ${mobileMediaQuery((css, mobileScale) => css`
    display: flex;
    position: fixed;
    top: 0;
    background-color: #FFFFFF;
    width: 100%;
    justify-content: space-between;
    min-height: ${mobileScale(55)};
    align-items: center;
    padding-left: ${mobileScale(14)};
    padding-right: ${mobileScale(19)};
    border-bottom: ${mobileScale(0.5)} solid rgba(75, 75, 75, 0.2);
    z-index: 99;
  `)}
`

const ModalTitle = styled.h3`
  font-weight: 500;
  font-size: ${horizontalScale(36)};
  line-height: ${horizontalScale(44)};
  margin-bottom: ${horizontalScale(44)};
  text-transform: uppercase;
  color: #98734F;

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(18)};
    line-height: ${mobileScale(22)};
    margin-bottom: ${mobileScale(17)};
  `)}
`

const ModalDescription = styled.p`
  font-size: ${horizontalScale(25)};
  line-height: ${horizontalScale(29)};
  max-width: ${horizontalScale(620)};
  margin-top: 0;
  margin-bottom: ${horizontalScale(59)};
  color: #9B9999;

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(16)};
    max-width: ${mobileScale(268)};
    margin-bottom: ${mobileScale(24)};
    padding: 0 ${mobileScale(16)};
  `)}
`

const CancelIcon = styled(CancelSVG)`
  width: ${horizontalScale(24)};
  height: ${horizontalScale(24)};
  cursor: pointer;
  
  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(13)};
    height: ${mobileScale(13)};
  `)}
`

const MasonryGalleryWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`

const MasonryGallery = styled.div`
  column-count: 2;
  column-gap: ${horizontalScale(60)};
  padding-bottom: ${horizontalScale(0)};

  .lazyload-wrapper {
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: ${horizontalScale(60)};
    break-inside: avoid;
    width: ${horizontalScale(695)};

    img {
      grid-row: 1 / -1;
      grid-column: 1;
      
    }
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    column-count: 1;
    column-gap: 0;
    padding: 0 ${mobileScale(16)};
    padding-bottom: ${mobileScale(24)};

    .lazyload-wrapper {
      width: ${mobileScale(288)};
      margin-bottom: ${mobileScale(24)};
    }
  `)}
`

const ModalItemsGalleryWrapper = styled.div`
  height: 100%;

  ${mobileMediaQuery((css, mobileScale) => css`
    padding: 0 ${mobileScale(16)} 100px;
  `)}
`

const ModalItemsGallery = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`

const GalleryImage = styled.img`
  width: ${horizontalScale(695)};

  ${mobileMediaQuery((css) => css`
    width: 100%;
  `)}
`

const LogoIcon = styled(LogoSVG)`
  display: none;

  ${mobileMediaQuery((css, mobileScale) => css`
    display: block;
    width: ${mobileScale(37)};
    height: ${mobileScale(37)};
  `)}
`


const ButtonsBlock = styled.div`
  display: none;
  justify-content: space-between;
  width: ${horizontalScale(120)};
  align-self: flex-end;
  margin-right: ${horizontalScale(25)};

  ${mobileMediaQuery((css) => css`
    display: none;
  `)}
`

const NavButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:active {
    circle {
      stroke: #FEF4D9;
    }
  }
`

const NextArrowIcon = styled(NextArrowSVG)`
  width: ${horizontalScale(54)};
  height: ${horizontalScale(54)};
  stroke-width: ${horizontalScale(2)};
`

const PrevArrowIcon = styled(NextArrowSVG)`
  width: ${horizontalScale(54)};
  height: ${horizontalScale(54)};
  stroke-width: ${horizontalScale(2)};
  transform: scaleX(-1);
`

const FormButtonWrapper = styled.div<{ isSticky?: boolean }>`
  ${props => props.isSticky && css`
    position: sticky;
    bottom: 0;
    background: #FFFFFF;
    height: ${horizontalScale(92)};

    ${mobileMediaQuery((css, mobileScale) => css`
      position: fixed;
      bottom: 0;
      background: #FFFFFF;
      height: ${mobileScale(54)};
    `)}
  `}

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const FormButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #5D5B60;
  padding: 0 ${horizontalScale(24)};
  width: ${horizontalScale(422)};
  height: ${horizontalScale(72)};
  margin-bottom: 0;
  background: transparent;
  text-decoration: none;
  color: #5D5B60;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: 0.3s ease-out;

  
  span {
    font-size: ${horizontalScale(20)};
    line-height: ${horizontalScale(24)};
    margin-top: ${horizontalScale(6)};
    font-family: 'TT Commons';
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.06em;
    text-align: center;
    text-decoration: none;
    color: #5D5B60;
    transition: 0.3s ease-out;
  }

  svg {
    transition: 0.3s ease-out;
    margin: auto 0;
  }

  &:hover {
    background: rgba(142, 161, 177, 0.3);
    border: 1px solid transparent;
  }
  
  &:active {
    border: 1px solid transparent;
    background: #8EA1B1;

    span {
      color: #FFFFFF;
    }

    svg rect {
      fill: #FFFFFF;
    }
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(288)};
    height: ${mobileScale(38)};
    padding: 0 ${mobileScale(16)};
    margin-bottom: 0;
    
    span {
      font-size: ${mobileScale(10)};
      line-height: ${mobileScale(12)};
      margin-top: ${mobileScale(3)};
      letter-spacing: 0.18em;
    }
  `)}
`

const DiamondIcon = styled(DiamondSVG)`
  width: ${horizontalScale(16)};
  height: ${horizontalScale(16)};

  rect {
    fill: #5D5B60;
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(8)};
    height: ${mobileScale(8)};
  `)}
`
