import { BrowserRouter, Routes, Route, useNavigate, redirect } from 'react-router-dom'
import {
  Main,
  About,
  Collections,
  Decoration,
  Filling,
  Order,
  Team,
  Advantages,
  Press,
  FAQ,
  Form,
  Contacts,
  Footer
} from './components'
import './App.css'
import Modal from 'react-modal'
import TagManager from 'react-gtm-module'
import { GlobalStyle } from 'components/collections'
import { useEffect } from 'react'


Modal.setAppElement('#root')

TagManager.initialize({ gtmId: 'GTM-PT37MQQ' })


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<HomePage />} />
        <Route path="cakes" element={<CakesPage />} />
        <Route path="wedding" element={<WeddingPage />} />
      </Routes>
    </BrowserRouter>
  )
}

const HomePage = () => (
  <div className="App" id='root'>
    {/*         MAIN                 */}
    <Main />
    {/*         Об Ателье            */}
    <About />
    {/*         Коллекции            */}
    <Collections />
    {/*         Начинки и декорации  */}
    <Decoration />
    {/*         Начинки              */}
    <Filling />
    {/*         Процесс Заказа       */}
    <Order />
    {/*         Наша Команда         */}
    <Team />
    {/*         Преимущества         */}
    <Advantages />
    {/*         Пресса о нас         */}
    <Press />
    {/*         FAQ                  */}
    <FAQ />
    {/*         Форма                */}
    <Form />
    {/*         Контакты             */}
    <Contacts />
    {/*         Футер                */}
    <Footer />
    {/*         Глобальные стили     */}
    <GlobalStyle />
  </div>
)

const CakesPage = () => {

  useEffect(() => {
    window.location.href = '/files/Каталог_тортов_Cake_Buro.pdf'
  }, [])

  return null
}

const WeddingPage = () => {

  useEffect(() => {
    window.location.href = '/files/Свадебный_альбом_Cake_Buro.pdf'
  }, [])

  return null
}

export default App
