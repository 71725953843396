// @ts-ignore
import { isWebpSupported } from 'react-image-webp/dist/utils'

import pressImgJpg_0 from 'assets/images/press_0.jpg'

import pressImgJpg_1 from 'assets/images/press_1.jpg'
import pressImgJpg_2 from 'assets/images/press_2.jpg'

import pressImgWebp_1 from 'assets/images/press_1.webp'
import pressImgWebp_2 from 'assets/images/press_2.webp'

const pressImg_0 = pressImgJpg_0

const pressImg_1 = isWebpSupported() ? pressImgWebp_1 : pressImgJpg_1

const pressImg_2 = isWebpSupported() ? pressImgWebp_2 : pressImgJpg_2

const ARTICLES_LIST = [
  {
    id: '0',
    title: 'Елена Железнякова — победительница голосования «Да, шеф! Women»',
    previewSrc: pressImg_0,
    link: 'https://www.restorating.ru/sch/articles/da-chief-msk-2022-final',
  },
  {
    id: '1',
    title: 'Десерты, которые вас удивят. Шеф-кондитер «Кофемании» Елена Железнякова о трендах в кондитерском деле',
    previewSrc: pressImg_1,
    link: 'https://www.gastronom.ru/text/shef-konditer-kofemanii-elena-shakarjan-o-trendah-1013649',
  },
  {
    id: '2',
    title: 'Елена Железнякова: «Я ни с кем не соревнуюсь, я просто делаю свою работу»',
    previewSrc: pressImg_2,
    link: 'http://www.horeca-magazine.ru/article/8211/',
  },
]

export default ARTICLES_LIST