// @ts-ignore
import { isWebpSupported } from 'react-image-webp/dist/utils'

import fillingImgJpg_1 from 'assets/images/filling_1.jpg'
import fillingImgJpg_2 from 'assets/images/filling_2.jpg'
import fillingImgJpg_3 from 'assets/images/filling_3.jpg'
import fillingImgJpg_4 from 'assets/images/filling_4.jpg'
import fillingImgJpg_5 from 'assets/images/filling_5.jpg'
import fillingImgJpg_6 from 'assets/images/filling_6.jpg'
import fillingImgJpg_7 from 'assets/images/filling_7.jpg'
import fillingImgJpg_8 from 'assets/images/filling_8.jpg'
import fillingImgJpg_9 from 'assets/images/filling_9.jpg'
import fillingImgJpg_10 from 'assets/images/filling_10.jpg'
import fillingImgJpg_11 from 'assets/images/filling_11.jpg'
import fillingImgJpg_12 from 'assets/images/filling_12.jpg'

import fillingImgWebp_1 from 'assets/images/filling_1.webp'
import fillingImgWebp_2 from 'assets/images/filling_2.webp'
import fillingImgWebp_3 from 'assets/images/filling_3.webp'
import fillingImgWebp_4 from 'assets/images/filling_4.webp'
import fillingImgWebp_5 from 'assets/images/filling_5.webp'
import fillingImgWebp_6 from 'assets/images/filling_6.webp'
import fillingImgWebp_7 from 'assets/images/filling_7.webp'
import fillingImgWebp_8 from 'assets/images/filling_8.webp'
import fillingImgWebp_9 from 'assets/images/filling_9.webp'
import fillingImgWebp_10 from 'assets/images/filling_10.webp'
import fillingImgWebp_11 from 'assets/images/filling_11.webp'
import fillingImgWebp_12 from 'assets/images/filling_12.webp'

const fillingImg_1 = isWebpSupported() ? fillingImgWebp_1 : fillingImgJpg_1
const fillingImg_2 = isWebpSupported() ? fillingImgWebp_2 : fillingImgJpg_2
const fillingImg_3 = isWebpSupported() ? fillingImgWebp_3 : fillingImgJpg_3
const fillingImg_4 = isWebpSupported() ? fillingImgWebp_4 : fillingImgJpg_4
const fillingImg_5 = isWebpSupported() ? fillingImgWebp_5 : fillingImgJpg_5
const fillingImg_6 = isWebpSupported() ? fillingImgWebp_6 : fillingImgJpg_6
const fillingImg_7 = isWebpSupported() ? fillingImgWebp_7 : fillingImgJpg_7
const fillingImg_8 = isWebpSupported() ? fillingImgWebp_8 : fillingImgJpg_8
const fillingImg_9 = isWebpSupported() ? fillingImgWebp_9 : fillingImgJpg_9
const fillingImg_10 = isWebpSupported() ? fillingImgWebp_10 : fillingImgJpg_10
const fillingImg_11 = isWebpSupported() ? fillingImgWebp_11 : fillingImgJpg_11
const fillingImg_12 = isWebpSupported() ? fillingImgWebp_12 : fillingImgJpg_12

const FILLINGS_LIST = [
  {
    id: '1',
    title: 'Клубничный фрамбуаз',
    description: 'Сливочно-ванильный мусс прослоенный клубничным кули, миндальный бисквит с апельсиновым конфитюром',
    src: fillingImg_12,
    isShow: true,
  },
  {
    id: '2',
    title: 'Фисташка-вишня',
    description: 'Вишневая начинка с муссом из фисташки, фисташковый бисквит с хрустящей прослойкой',
    src: fillingImg_11,
    isShow: true,
  },
  {
    id: '3',
    title: 'Балу',
    description: 'Мусс из шоколада и фундучного пралине, шоколадный бисквит на хрустящей вафельной основе под глазурью из молочного шоколада',
    src: fillingImg_1,
    isShow: true,
  },
  {
    id: '4',
    title: 'Эстерхази',
    description: 'Тонкие мягкие ореховые вафли, прослоенные сливочно-ореховым кремом пралине',
    src: fillingImg_2,
    isShow: true,
  },
  {
    id: '5',
    title: 'Сметанник',
    description: 'Тонкие медовые коржи, прослоенные сметанным кремом',
    src: fillingImg_3,
    isShow: true,
  },
  {
    id: '6',
    title: 'Экзотик',
    description: '',
    src: fillingImg_9,
    isShow: true,
  },
  {
    id: '7',
    title: 'Ягодная',
    description: '',
    src: fillingImg_10,
    isShow: true,
  },
  {
    id: '8',
    title: 'Кабошон',
    description: 'Миндально-шоколадный бисквит, ягодный мусс и начинка из лесных ягод  без глютена, без лактозы',
    src: fillingImg_4,
    isShow: false,
  },
  {
    id: '9',
    title: 'Шоколад-малина',
    description: 'Шоколадный бисквит с миндальной мукой, сливочно-сырный и шоколадный крема и свежая малина',
    src: fillingImg_5,
    isShow: false,
  },
  {
    id: '10',
    title: 'Шато',
    description: 'Шоколадный бисквит с миндальной мукой, сливочно-сырный и шоколадный крема и свежая малина',
    src: fillingImg_6,
    isShow: true,
  },
  {
    id: '11',
    title: 'Рейк',
    description: 'Карамельный и медовый бисквиты, хрястящая прослойка с пралине, карамельно-шоколадный крем и крем с вареной сгущенкой, мягкий чернослив',
    src: fillingImg_7,
    isShow: true,
  },
  {
    id: '12',
    title: 'Лимон-земляника',
    description: 'Карамельный и медовый бисквиты, хрястящая прослойка с пралине, карамельно-шоколадный крем и крем с вареной сгущенкой, мягкий чернослив',
    src: fillingImg_8,
    isShow: true,
  },
]

export default FILLINGS_LIST