import styled from 'styled-components'
import { slide as Menu } from 'react-burger-menu'
import { Link } from 'react-scroll'
// import Image from 'react-image-webp'
// @ts-ignore
import { isWebpSupported } from 'react-image-webp/dist/utils'
import { FormModal } from '../../components'
import { horizontalScale, mobileMediaQuery, useIsMobile } from 'utils/helpers'
import mainBackgroundImgPng from 'assets/images/main-background.png'
import mainBackgroundImgWebp from 'assets/images/main-background.webp'
import { ReactComponent as LogoSVG } from 'assets/svg/logo.svg'
import { ReactComponent as PhoneSVG } from 'assets/svg/phone.svg'
import { ReactComponent as UnionSVG } from 'assets/svg/union.svg'
import { ReactComponent as DiamondSVG } from 'assets/svg/diamond.svg'
import { ReactComponent as CrossSVG } from 'assets/svg/cross.svg'
import { ReactComponent as BurgerSVG } from 'assets/svg/burger.svg'
import { useState } from 'react'

const mainBackgroundImg = isWebpSupported()
  ? mainBackgroundImgWebp
  : mainBackgroundImgPng

const Main = () => {
  const isMobile = useIsMobile()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const [isFormOpen, setIsFormOpen] = useState(false)

  const openMenu = () => setIsMenuOpen(true)

  const closeMenu = () => setIsMenuOpen(false)

  const openForm = () => setIsFormOpen(true)

  const closeForm = () => setIsFormOpen(false)

  return (
    <Header id='main'>
      {isMobile && (
        <Menu
          isOpen={isMenuOpen}
          customBurgerIcon={false}
          onStateChange={({ isOpen }) => setIsMenuOpen(isOpen)}
          outerContainerId='main'
          width='100%'
        >
          <MobileMenuContainer>
            <MobileMenuTop>
              <LogoIcon />
              <CrossIcon onClick={closeMenu} />
            </MobileMenuTop>
            <MobileNavigation>
              <MobileLinksList>
                <li>
                  <Link
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={closeMenu}
                    to='about'
                  >
                    ОБ АТЕЛЬЕ
                  </Link>
                </li>
                <li>
                  <Link
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={closeMenu}
                    to='collections'
                  >
                    КОЛЛЕКЦИИ
                  </Link>
                </li>
                <li>
                  <Link
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={closeMenu}
                    to='decoration'
                  >
                    НАЧИНКИ И ДЕКОР
                  </Link>
                </li>
                <li>
                  <Link
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={closeMenu}
                    to='contacts'
                  >
                    КОНТАКТЫ
                  </Link>
                </li>
              </MobileLinksList>
              <MobileEmailLink
                href='mailto:cakeburo@coffeemania.ru'
                onClick={closeMenu}
              >
                cakeburo@coffeemania.ru
              </MobileEmailLink>
              <MobilePhoneLink
                href='tel:+79857861661'
                onClick={closeMenu}
              >
                Tel. +7 985 786 16 61
              </MobilePhoneLink>
            </MobileNavigation>
          </MobileMenuContainer>
        </Menu>
      )}
      <HeaderTop>
        <LogoBlock href='/'> 
          <LogoIcon />
          <span>Кондитерское ателье премиум-класса от «Кофемании»</span>
        </LogoBlock>
        <UnionIcon />
        <Navigation>
          <Link to='about' spy={true} smooth={true} duration={500}>
            ОБ АТЕЛЬЕ
          </Link>
          <Link to='collections' spy={true} smooth={true} duration={500}>
            КОЛЛЕКЦИИ
          </Link>
          <Link to='decoration' spy={true} smooth={true} duration={500}>
            НАЧИНКИ И ДЕКОР
          </Link>
          <Link to='contacts' spy={true} smooth={true} duration={500}>
            КОНТАКТЫ
          </Link>
        </Navigation>
        <PhoneLink href='tel:+79857861661'>
          <PhoneSVG />
        </PhoneLink>
        <BurgerButton onClick={openMenu} />
      </HeaderTop>
      <HeaderBottom>
        <Title>
          Каждому празднику свой торт
        </Title>
        <Description>
          Лучшие свадебные кондитеры премии Wedding Awards<br />
          Чемпионы России по кондитерскому искусству PIR EXPO*<br />
          Шеф-кондитер Елена Железнякова — женщина-шеф №1 в Москве**<br />
          Кондитер года-2022***<br /><br />
        </Description>
        <SubDescription>
          * Награды 2019 гг.<br />
          ** В рейтинге Restorating «Да, шеф! Woman» 2022 г.<br />
          *** Премия «Лучшие в индустрии», форум «Пальмовая ветвь ресторанного бизнеса»
        </SubDescription>
        <RequestButton  onClick={openForm}>
          <DiamondIcon />
          <span>отправить запрос</span>
          <DiamondIcon />
        </RequestButton>
      </HeaderBottom>
      <FormModal isOpen={isFormOpen} onRequestClose={closeForm} />
    </Header>
  )
}

export default Main

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-image: url(${mainBackgroundImg});
  background-size: ${horizontalScale(1254)} ${horizontalScale(920)};
  background-position-x: ${horizontalScale(634)};
  background-position-y: ${horizontalScale(81)};
  background-color: rgb(142, 161, 177);
  background-repeat: no-repeat;
  height: ${horizontalScale(1000)};
  
  ${mobileMediaQuery((css, mobileScale) => css`
    height: ${mobileScale(564)};
    background-size: cover;
    background-position-x: ${mobileScale(-60)};
    padding-top: ${mobileScale(55)};

    .bm-burger-button {
      width: ${mobileScale(20)};
      height: ${mobileScale(13)};
      left: 36px;
      top: 36px;
      background-color: blue;
    }

    .bm-overlay {
      top: 0;
      left: 0;
    }

    .bm-menu-wrap {
      width: 100%;
      top: 0;
      left: 0;
    }
  `)}
`

const HeaderTop = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: ${horizontalScale(180)};
  align-items: center;
  justify-content: space-between;
  padding: 0 ${horizontalScale(84)};

  ${mobileMediaQuery((css, mobileScale) => css`
    position: fixed;
    top: 0;
    z-index: 10;
    background-color: #FFFFFF;
    height: ${mobileScale(55)};
    padding: 0 ${mobileScale(16)};
  `)}
`

const HeaderBottom = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: ${horizontalScale(180)};
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 ${horizontalScale(70)};

  ${mobileMediaQuery((css, mobileScale) => css`
    height: ${mobileScale(55)};
    padding: 0 ${mobileScale(16)};
  `)}
`

const UnionIcon = styled(UnionSVG)`
  position: absolute;
  left: calc(50% - ${horizontalScale(17)});   /* (34 / 2) */
  width: ${horizontalScale(34)};
  height: ${horizontalScale(34)};
`

const LogoIcon = styled(LogoSVG)`
  width: ${horizontalScale(91)};
  height: ${horizontalScale(90)};

  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(40)};
    height: ${mobileScale(40)};
  `)}
`

const DiamondIcon = styled(DiamondSVG)`
  width: ${horizontalScale(18)};
  height: ${horizontalScale(18)};

  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(8)};
    height: ${mobileScale(8)};
  `)}
`

const LogoBlock = styled.a`
  display: flex;
  flex: 0 0 auto;
  align-items: center;

  span {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    font-size: ${horizontalScale(12)};
    line-height: ${horizontalScale(16)};
    width: ${horizontalScale(144)};
    text-align: start;
    margin-left: ${horizontalScale(22)};
    color: #FFFFFF;
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    align-items: center;
  
    span {
      font-family: Cormorant;
      width: ${mobileScale(118)};
      font-weight: 500;
      font-size: ${mobileScale(10)};
      line-height: ${mobileScale(14)};
      text-transform: uppercase;
      color: #AFA67B;
      margin-left: ${mobileScale(8)};
    }
  `)}
`

const Navigation = styled.nav`
  display: flex;
  flex: 0 0 auto;
  height: ${horizontalScale(14)};
  margin-right: ${horizontalScale(20)};

  a {
    font-size: ${horizontalScale(14)};
    letter-spacing: 0.025em;
    line-height: 77.1%;
    font-style: normal;
    font-weight: bold;
    color: #FFFFFF;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: ${horizontalScale(64)};
    }
  }

  ${mobileMediaQuery((css) => css`
    display: none;
  `)}
`

const PhoneLink = styled.a`
  display: none;

  ${mobileMediaQuery((css, mobileScale) => css`
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: ${mobileScale(17.54)};

    svg {
      width: ${mobileScale(14.46)};
      height: ${mobileScale(14.46)};
    }
  `)}
`

const BurgerButton = styled(BurgerSVG)`
  display: none;

  ${mobileMediaQuery((css, mobileScale) => css`
    display: flex;
    width: ${mobileScale(16)};
    height: ${mobileScale(16)};
  `)}
`

const MobileMenuContainer = styled.div`
  display: none;

  ${mobileMediaQuery((css, mobileScale) => css`
      width: 100%;
      height: 100%;
      background-color: #FFFFFF;
      height: 100%;
      padding: 0 ${mobileScale(23)};
      padding-bottom: ${mobileScale(16)};
    `)}
  `

const MobileMenuTop = styled.div`
  display: none;

  ${mobileMediaQuery((css, mobileScale) => css`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: ${mobileScale(56)};
  `)}
`

const CrossIcon = styled(CrossSVG)`
  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(13)};
    height: ${mobileScale(13)};
  `)}
`

const MobileNavigation = styled.nav`
  display: none;

  ${mobileMediaQuery((css, mobileScale) => css`
    padding-top: ${mobileScale(67)};
    width: 100%;
    display: flex;
    flex-direction: column;
  `)}
`

const MobileLinksList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;

  ${mobileMediaQuery((css, mobileScale) => css`
    margin-bottom: ${mobileScale(150)};
  
    li {
      display: flex;
      justify-content: flex-start;

      &:not(:last-child) {
        margin-bottom: ${mobileScale(32)};
      }
    }
    
    li > a {
      font-weight: 500;
      font-size: ${mobileScale(16)};
      line-height: ${mobileScale(18)};
      letter-spacing: 0.025em;
      color: #9B9999;
      text-align: left;
    }
  `)}
`

const MobileEmailLink = styled.a`
  ${mobileMediaQuery((css, mobileScale) => css`
    font-weight: 500;
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(14)};
    color: #9B9999;
    text-align: left;
    margin-bottom: ${mobileScale(21)};
    margin-top: auto;
  `)}
`

const MobilePhoneLink = styled.a`
  ${mobileMediaQuery((css, mobileScale) => css`
    font-weight: 500;
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(16)};
    color: #9B9999;
    text-align: left;

    &:not(:last-child) {
      margin-bottom: ${mobileScale(10)};
    }
  `)}
`

const Title = styled.h1`
  font-size: ${horizontalScale(120)};
  line-height: ${horizontalScale(140)};
  margin-top: ${horizontalScale(30)};
  margin-bottom: ${horizontalScale(48)};
  max-width: ${horizontalScale(1026)};
  font-weight: 500;
  text-align: left;
  background:
    linear-gradient(95.27deg, #CABC8A 28.76%, rgba(193, 176, 114, 0.73) 55.34%, rgba(255, 255, 255, 0) 76.91%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(30)};
    line-height: ${mobileScale(36)};
    margin-top: ${mobileScale(38)};
    margin-bottom: ${mobileScale(32)};
    max-width: ${mobileScale(187)};
    font-weight: 400;
  `)}
`

const Description = styled.span`
  font-style: normal;
  font-weight: normal;
  color: #FFFFFF;
  font-size: ${horizontalScale(20)};
  line-height: ${horizontalScale(24)};
  text-align: left;
  letter-spacing: -0.005em;
  max-width: ${horizontalScale(550)};
  height: ${horizontalScale(106)};
  margin-bottom: ${horizontalScale(4)};

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(12)};
    line-height: ${mobileScale(18)};
    max-width: ${mobileScale(200)};
    height: ${mobileScale(128)};
    margin-bottom: ${mobileScale(16)};
  `)}
`

const SubDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  color: #FFFFFF;
  font-size: ${horizontalScale(16)};
  line-height: ${horizontalScale(18)};
  text-align: left;
  letter-spacing: -0.005em;
  max-width: ${horizontalScale(550)};
  height: ${horizontalScale(46)};
  margin-bottom: ${horizontalScale(50)};
  white-space: nowrap;

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(9)};
    line-height: ${mobileScale(12)};
    max-width: ${mobileScale(192)};
    height: ${mobileScale(53)};
    margin-bottom: ${mobileScale(20)};
    margin-left: ${mobileScale(-7)};
    text-indent: ${horizontalScale(18)};
    white-space: normal;
  `)}
`

const RequestButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #FFFFFF;
  font-family: 'TT Commons' !important;
  padding: 0 ${horizontalScale(30)};
  width: ${horizontalScale(422)};
  height: ${horizontalScale(72)};
  background: transparent;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;

  span {
    color: #ffffff;
    font-size: ${horizontalScale(20)};
    line-height: ${horizontalScale(24)};
    margin-top: ${horizontalScale(6)};
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.18em;
    text-align: center;
  }

  svg {
    margin: auto 0;
  }

  svg {
    transition: 0.3s ease-out;
    margin: auto 0;
  }

  &:hover {
    background: rgba(196, 196, 196, 0.3);;
    border: 1px solid transparent;
  }
  
  &:active {
    border: 1px solid transparent;
    background: #FFFFFF;

    span {
      color: #5D5B60;;
    }

    svg rect {
      fill: #5D5B60;
    }
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(174)};
    height: ${mobileScale(38)};
    padding: 0 ${mobileScale(16)};

    span {
      height: ${mobileScale(14)};
      font-size: ${mobileScale(10)};
      line-height: ${mobileScale(14)};
      margin-top: ${mobileScale(3)};
    }

    svg {
      margin-top: ${mobileScale(15)};
    }
  `)}
`

