import styled, { css } from 'styled-components'
import Tippy from '@tippyjs/react'
import { horizontalScale, mobileMediaQuery } from 'utils/helpers'
import ORDER_LIST from 'components/order/mockups'
import { ReactComponent as TooltipQuestionSVG } from 'assets/svg/tooltip_question.svg'
import { useState } from 'react'

const Order = () => {
  const [selectedTooltipId, setSelectedTooltipId] = useState<string | undefined>()

  const toggleTooltip = (id: string) => {
    if (id === selectedTooltipId) setSelectedTooltipId(undefined)
    else setSelectedTooltipId(id)
  }


  return (
    <Section id='order'>
      <Title>Процесс заказа</Title>
      <Description>
        <div>Заказать торт в Cake Buro очень просто: нужно выполнить несколько понятных шагов</div>
      </Description>
      <StepsWrapper>
        {ORDER_LIST.map(({ id, tooltip_title, tooltip_description, icon_src }) => (
          <Step key={`order_${id}`} iconSrc={icon_src}>
            <Tippy
              content={(
                <TooltipContainer>
                  <TooltipTitle>{tooltip_title}</TooltipTitle>
                  {tooltip_description}
                </TooltipContainer>
              )}
              onClickOutside={() => setSelectedTooltipId(undefined)}
              visible={(id === selectedTooltipId)}
              placement='top'
              onShown={console.log}
              className='order_tooltip'
              duration={0}
            >
              <TooltipQuestionIcon
                isToggled={(id === selectedTooltipId)}
                onClick={() => toggleTooltip(id)}
              />
            </Tippy>
            <StepTitle>{tooltip_title}</StepTitle>
            <StepDescription>{tooltip_description}</StepDescription>
          </Step>
        ))}
      </StepsWrapper>
    </Section>
  )
}

export default Order

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(0deg, #8EA1B1, #8EA1B1), #FFFFFF;
  padding: ${horizontalScale(160)} ${horizontalScale(70)};

  ${mobileMediaQuery((css, mobileScale) => css`
    padding: ${mobileScale(64)} ${mobileScale(16)};
  `)}
`

const Title = styled.h2`
  font-weight: 500;
  font-size: ${horizontalScale(48)};
  line-height:${horizontalScale(58)};
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: ${horizontalScale(40)};

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(18)};
    line-height:${mobileScale(22)};
    margin-bottom: ${mobileScale(16)};
  `)}
`

const Description = styled.span`
  font-size: ${horizontalScale(24)};
  line-height:${horizontalScale(29)};
  color: #FFFFFF;
  margin-bottom: ${horizontalScale(72)};
  max-width: ${horizontalScale(574)};
  text-align: center;

  & > div:has(strong) {
    font-size: ${horizontalScale(28)};
    line-height: ${horizontalScale(32)};
    margin-top: ${horizontalScale(24)};
    margin-bottom: ${horizontalScale(24)};
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height:${mobileScale(17)};
    max-width: ${mobileScale(288)};
    margin-bottom: ${mobileScale(32)};

    & > div:has(strong) {
      font-size: ${mobileScale(16)};
      line-height: ${mobileScale(20)};
      margin-top: ${mobileScale(10)};
      margin-bottom: ${mobileScale(10)};
    }
  `)}
`

const StepsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap:${horizontalScale(24)};
  width: 100%;

  ${mobileMediaQuery((css, mobileScale) => css`
    flex-direction: column;
    justify-content: flex-start;
    width: ${mobileScale(186)};
  `)}
`

const Step = styled.div<{ iconSrc: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: url(${props => props.iconSrc});
  background-repeat: no-repeat;
  background-size: ${horizontalScale(54)};
  padding-top: ${horizontalScale(78)};
  width: ${horizontalScale(423)};
  height: ${horizontalScale(310)};

  ${mobileMediaQuery((css, mobileScale) => css`
    width: 100%;
    height: auto;
    padding-top: ${mobileScale(48)};
    background-size: ${mobileScale(32)};

    &:not(:last-child) {
      margin-bottom: ${mobileScale(32)};
    }
  `)}
`

const StepTitle = styled.span`
  font-weight: 500;
  font-size: ${horizontalScale(24)};
  line-height:${horizontalScale(29)};
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: ${horizontalScale(16)};
  text-align: left;

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height:${mobileScale(17)};
    margin-bottom: ${mobileScale(8)};
  `)}
`

const StepDescription = styled.span`
  font-size: ${horizontalScale(24)};
  line-height:${horizontalScale(29)};
  color: #FFFFFF;
  text-align: left;

  & > p {
    margin: 0;
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height:${mobileScale(17)};
  `)}
`

const TooltipContainer = styled.div`
  width: ${horizontalScale(515)};
  padding: ${horizontalScale(24)};
  background: #FEF4D9;

  p {
    font-size: ${horizontalScale(18)} !important;
    line-height: ${horizontalScale(28)} !important;
    color: #8EA1B1 !important;
  }
`

const TooltipQuestionIcon = styled(TooltipQuestionSVG)<{ isToggled: boolean }>`
  display: none;
  cursor: pointer;
  position: absolute;
  width: ${horizontalScale(24)};
  height: ${horizontalScale(24)};
  left: ${horizontalScale(62)};
  top: 0;

  ${props => props.isToggled && css`
    circle {
      fill: #FEF4D9;
    }
    path {
      fill: #8EA1B1;
    }
  `}

  ${mobileMediaQuery((css) => css`
    display: none;
  `)}
`

const TooltipTitle = styled.span`
  font-size: ${horizontalScale(18)} !important;
  line-height: ${horizontalScale(28)} !important;
  font-weight: 600 !important;
  color: #8EA1B1 !important;
  margin-bottom: ${horizontalScale(16)} !important;
`
