const DECORATION_LIST = [
  {
    id: '1',
    text: 'Мастера Cake Buro с 2001 года создают авторские торты по индивидуальным заказам. Их многолетний опыт и профессионализм позволяют превращать идеи и желания гостей в настоящие шедевры кондитерского искусства.',
  },
  {
    id: '2',
    text: 'Для декора мы используем качественные пищевые ингредиенты.<br />Все детали оформления, будь то лепесток, узор или профиль греческой богини, создаются кондитерами вручную.',
  },
]

export default DECORATION_LIST
