import { useState, useRef } from 'react'
import styled from 'styled-components'
// @ts-ignore
import { isWebpSupported } from 'react-image-webp/dist/utils'
import { horizontalScale, mobileMediaQuery, useDidUpdate, useIsMobile } from 'utils/helpers'
import useCollapse from 'react-collapsed';
import faqImgJpg from 'assets/images/faq.jpg'
import faqImgWebp from 'assets/images/faq.webp'
import { ReactComponent as PlusSvg } from 'assets/svg/plus.svg'
import { ReactComponent as MinusSvg } from 'assets/svg/minus.svg'
import { FAQ_LIST } from 'components/faq/mockup'
import { GetCollapsePropsInput } from 'react-collapsed/dist/types'

const faqImg = isWebpSupported() ? faqImgWebp : faqImgJpg

const FAQ = () => {
  const faqSectionRef = useRef<HTMLElement>(null)
  const isMobile = useIsMobile()
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([])
  const [isListExpanded, setListExpanded] = useState(false)

  const faqItemOffset = isMobile ? 4 : 5

  const toggleListExpand = (e: any) => {
    setListExpanded((value) => !value)
    if (isListExpanded) {
      scrollTo(faqSectionRef.current, -70)
    }
  }

  const toggleFaqItem = (id: string, e: any) => {
    scrollTo(e.target)
    setSelectedItemIds((itemIds) => {
      const isIdInList = itemIds.includes(id)
      if (isIdInList) {
        return [ ...itemIds.filter(itemId => (itemId !== id))]
      } else {
        return [ ...itemIds, id ]
      }
    })
  }

  const scrollTo = (target: any, yOffset = 0) => {
    setTimeout(() => {
      const isMobile = window.innerWidth < 769
      yOffset += isMobile ? 75: 15
      const y = target.getBoundingClientRect().top + window.pageYOffset - yOffset
      window.scrollTo({top: y, behavior: 'smooth'})
    }, 300)
  }

  return (
    <Section id='faq' ref={faqSectionRef}>
      <Title>FAQ</Title>
      <Description>Ответы на популярные вопросы:</Description>
      
      <div>
        {FAQ_LIST.map(({ id, question, answer }, index) => {
          if (!isListExpanded && (index > faqItemOffset)) return null

          const isExpanded = selectedItemIds.includes(id)

          return (
            <FaqItem
              key={`faq-item-${id}`}
              isExpanded={isExpanded}
              onToggle={(e) => toggleFaqItem(id, e)}
              question={question}
              answer={answer}
            />
          )
        })}
      </div>
      <ListExpandButton onClick={(e) => toggleListExpand(e)}>
        {isListExpanded
          ? 'Свернуть'
          : 'Показать все вопросы'}
      </ListExpandButton>
    </Section>
  )
}

export default FAQ

const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: url(${faqImg}) no-repeat right, linear-gradient(0deg, #FEF4D9, #FEF4D9), linear-gradient(0deg, #FEF4D9, #FEF4D9), #FFFFFF;
  background-size: ${horizontalScale(572)} ${horizontalScale(710)};
  background-position-x: ${horizontalScale(1127)};
  background-position-y: ${horizontalScale(160)};
  padding: ${horizontalScale(160)} ${horizontalScale(221)};

  ${mobileMediaQuery((css, mobileScale) => css`
    background: linear-gradient(0deg, #FEF4D9, #FEF4D9), linear-gradient(0deg, #FEF4D9, #FEF4D9), #FFFFFF;
    padding:
      ${mobileScale(64)}
      ${mobileScale(16)};
  `)}
`

const Title = styled.h2`
  font-size: ${horizontalScale(48)};
  line-height: ${horizontalScale(58)};
  letter-spacing: 0.025em;
  color: #170000;
  margin-bottom: ${horizontalScale(40)};

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(18)};
    line-height: ${mobileScale(22)};
    margin-bottom: ${mobileScale(16)};
    text-align: center;
  `)}
`

const Description = styled.span`
  font-size: ${horizontalScale(24)};
  line-height: ${horizontalScale(29)};
  letter-spacing: 0.03em;
  color: #170000;
  width: ${horizontalScale(724)};
  margin-bottom: ${horizontalScale(72)};

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(17)};
    margin-bottom: ${mobileScale(32)};
    width: ${mobileScale(288)};
    text-align: center;
  `)}
`

const Collapsable = styled.div<GetCollapsePropsInput>`
  overflow: hidden;
  transition: all .4s ease-out;

  ul {
    list-style: none;
    padding: 0;

    li {
      font-family: 'TT Commons';
      font-size: ${horizontalScale(20)};
      line-height: ${horizontalScale(24)};
    }
  }

  p {
    transition: all .4s ease-out;
    height: auto;
    font-size: ${horizontalScale(20)};
    line-height: ${horizontalScale(24)};
    letter-spacing: 0.06em;
    margin: 0;
    padding-top: ${horizontalScale(24)};
    color: #170000;

    br {
      content: '' !important;
      display: block !important;
      margin-bottom: ${horizontalScale(17)} !important;
    }
  }

  &:not(:last-child) {
    margin-bottom: ${horizontalScale(40)};
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    ul {
      li {
        font-size: ${mobileScale(12)};
        line-height: ${mobileScale(14)};
      }
    }

    p {
      font-size: ${mobileScale(12)};
      line-height: ${mobileScale(14)};
      padding-top: ${mobileScale(8)};

      br {
        margin-bottom: ${mobileScale(21)} !important;
      }
    }

    &:not(:last-child) {
      margin-bottom: ${mobileScale(16)};
    }
  `)}
`;

const CollapsableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #170000;
  width: ${horizontalScale(724)};
  cursor: pointer;

  span {
    width: ${horizontalScale(676)};
    font-size: ${horizontalScale(24)};
    line-height: ${horizontalScale(29)};
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(17)};
    width: ${mobileScale(288)};

    span {
      width: ${mobileScale(237)};
      font-size: ${mobileScale(14)};
      line-height: ${mobileScale(17)};
    }
  `)}
`

const CollapsableContent = styled.div`
  max-width: ${horizontalScale(676)};

  ${mobileMediaQuery((css, mobileScale) => css`
    max-width: ${mobileScale(237)};
  `)}
`

type CollapsableProps = {
  className?: string
  onToggle: (e: any) => void
  question: string
  answer: JSX.Element
  isExpanded: boolean
}

const  FaqItem = (props: CollapsableProps) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    isExpanded: props.isExpanded,
  });

  return (
    <Collapsable {...getCollapseProps}>
      <CollapsableHeader {...getToggleProps({ onClick: props.onToggle })}>
        <span>{props.question}</span>
        {isExpanded ? <MinusIcon /> : <PlusIcon />}
      </CollapsableHeader>
      <CollapsableContent {...getCollapseProps({ style: { } })}>
        {props.answer}
      </CollapsableContent>
    </Collapsable>
  )
}

const ListExpandButton = styled.a`
  font-size: ${horizontalScale(20)};
  line-height: ${horizontalScale(24)};
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #170000;
  margin-top: ${horizontalScale(48)};
  cursor: pointer;
  
  ${mobileMediaQuery((css, mobileScale) => css`
    margin-top: ${mobileScale(32)};
    font-size: ${mobileScale(12)};
    line-height: ${mobileScale(14)};
  `)}
`

const PlusIcon = styled(PlusSvg)`
  width: ${horizontalScale(26)};
  height: ${horizontalScale(26)};
  pointer-events: none;

  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(12)};
    height: ${mobileScale(12)};
  `)}
`

const MinusIcon = styled(MinusSvg)`
  width: ${horizontalScale(24)};
  height: ${horizontalScale(2)};
  pointer-events: none;

  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(12)};
    height: ${mobileScale(1)};
  `)}
`