import styled from 'styled-components'
import { horizontalScale, mobileMediaQuery } from 'utils/helpers'
import ADVANTAGES_LIST from './mockups'

const Advantages = () => (
  <Section id='advantages'>
    <Title>наши преимущества</Title>
    <StepsWrapper>
      {ADVANTAGES_LIST.map(({ id, title, description, icon_src }) => (
        <Step key={`advantage_${id}`} iconSrc={icon_src}>
          <StepTitle>{title}</StepTitle>
          <StepDescription>
            {description}
          </StepDescription>
        </Step>
      ))}
    </StepsWrapper>
  </Section>
)

export default Advantages

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #8EA1B1;
  padding: ${horizontalScale(160)} ${horizontalScale(70)};

  ${mobileMediaQuery((css, mobileScale) => css`
    padding: ${mobileScale(64)} ${mobileScale(16)};
    flex-direction: column;
    justify-content: flex-start;
  `)}
`

const Title = styled.h2`
  font-weight: 500;
  font-size: ${horizontalScale(48)};
  line-height:${horizontalScale(58)};
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: ${horizontalScale(72)};

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(18)};
    line-height:${mobileScale(22)};
    margin-bottom: ${mobileScale(32)};
  `)}
`

const StepsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap:${horizontalScale(24)};
  width: 100%;

  ${mobileMediaQuery((css, mobileScale) => css`
    flex-direction: column;
    justify-content: flex-start;
    width: ${mobileScale(186)};
  `)}
`

const Step = styled.div<{ iconSrc: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: url(${props => props.iconSrc});
  background-repeat: no-repeat;
  background-size: ${horizontalScale(54)};
  padding-top: ${horizontalScale(78)};
  width: ${horizontalScale(423)};
  height: ${horizontalScale(310)};

  ${mobileMediaQuery((css, mobileScale) => css`
    width: 100%;
    height: auto;
    padding-top: ${mobileScale(48)};
    background-size: ${mobileScale(32)};

    &:not(:last-child) {
      margin-bottom: ${mobileScale(32)};
    }
  `)}
`

const StepTitle = styled.span`
  font-weight: 500;
  font-size: ${horizontalScale(24)};
  line-height:${horizontalScale(29)};
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: ${horizontalScale(16)};
  text-align: left;

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height:${mobileScale(17)};
    margin-bottom: ${mobileScale(8)};
  `)}
`

const StepDescription = styled.span`
  font-size: ${horizontalScale(24)};
  line-height:${horizontalScale(29)};
  color: #FFFFFF;
  text-align: left;

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height:${mobileScale(17)};
  `)}
`
