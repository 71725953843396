import styled from 'styled-components'
import { horizontalScale, mobileMediaQuery } from 'utils/helpers'

const Contacts = () => (
  <Section id='contacts'>
    <Title>Контакты</Title>
    <Links>
      <PhoneLink href='tel:+79857861661'>+7 985 786 16 61</PhoneLink>
      <Label>E-mail:</Label>
      <EmailLink href='mailto:cakeburo@coffeemania.ru'>cakeburo@coffeemania.ru</EmailLink>
    </Links>
    <Info>
      <DeliveryInfo>Доставляем по всему миру</DeliveryInfo>
      <Label>Часы работы:</Label>
      <WorkHoursInfo>с 10:00 до 20:00</WorkHoursInfo>
    </Info>
  </Section>
)

export default Contacts

const Section = styled.section`
  display: flex;
  background: linear-gradient(0deg, #8EA1B1, #8EA1B1);
  padding: ${horizontalScale(160)} ${horizontalScale(221)};

  ${mobileMediaQuery((css, mobileScale) => css`
    flex-direction: column;
    padding: ${mobileScale(64)} ${mobileScale(16)};
  `)}
`

const Title = styled.h2`
  font-size: ${horizontalScale(48)};
  line-height: ${horizontalScale(58)};
  width: ${horizontalScale(422)};
  font-weight: 500;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-top: 0;
  margin-bottom: ${horizontalScale(55)};
  margin-right: ${horizontalScale(181)};

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(18)};
    line-height: ${mobileScale(22)};
    margin-bottom: ${mobileScale(32)};
    margin-right: 0;
  `)}
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  width: ${horizontalScale(422)};
  margin-right: ${horizontalScale(30)};

  ${mobileMediaQuery((css, mobileScale) => css`
    margin-right: 0;
    width: 100%;
    margin-bottom: ${mobileScale(16)};
  `)}
`

const PhoneLink = styled.a`
  font-weight: 500;
  font-size: ${horizontalScale(32)};
  line-height: ${horizontalScale(52)};
  letter-spacing: 0.06em;
  color: #FFFFFF;
  margin-bottom: ${horizontalScale(24)};
  text-align: left;
  
  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(18)};
    line-height: ${mobileScale(22)};
    margin-bottom: ${mobileScale(16)};
  `)}
`

const Label = styled.span`
  font-weight: 600;
  font-size: ${horizontalScale(24)};
  line-height: ${horizontalScale(29)};
  margin-bottom: ${horizontalScale(16)};
  color: #FFFFFF;
  text-align: left;

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(15)};
    margin-bottom: ${mobileScale(4)};
    order: 1;
  `)}
`

const EmailLink = styled.a`
  font-weight: 500;
  font-size: ${horizontalScale(24)};
  line-height: ${horizontalScale(29)};
  color: #FFFFFF;
  text-align: left;

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(14)};
    order: 3;
  `)}
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
`

const DeliveryInfo = styled.span`
  font-weight: 500;
  font-size: ${horizontalScale(24)};
  line-height: ${horizontalScale(29)};
  color: #FFFFFF;
  margin-top: ${horizontalScale(15)};
  margin-bottom: ${horizontalScale(10)};
  height: ${horizontalScale(51)};
  text-align: left;

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(17)};
    order: 3;
  `)}
`

const WorkHoursInfo = styled.span`
  font-weight: 500;
  font-size: ${horizontalScale(24)};
  line-height: ${horizontalScale(29)};
  color: #FFFFFF;
  text-align: left;

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(17)};
    margin-bottom: ${mobileScale(16)};
    order: 2;
  `)}
`