import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react';
import { horizontalScale, mobileMediaQuery } from 'utils/helpers'
import { ReactComponent as NextArrowSVG } from 'assets/svg/next_arrow.svg'
import FILLINGS_LIST from './mockup'
import 'swiper/swiper-bundle.css'


const Filling = () => {
  const swiperRef = useRef<any>(null)

  const [activeIndex, setActiveIndex] = useState(0)

  const onPressPrev = () => swiperRef.current?.slidePrev()

  const onPressNext = () => swiperRef.current?.slideNext()

  useEffect(() => {
    swiperRef.current?.slideTo(activeIndex)
  }, [activeIndex])

  const fillingList = FILLINGS_LIST.filter(({ isShow }) => isShow)

  return (
    <Section id='filling'>
      <Title>Начинки тортов</Title>
      <Description>Вы можете выбрать начинку и дополнительные ингредиенты на свой вкус:</Description>
      <SwiperContainer
        onSwiper={(swiper) => { swiperRef.current = swiper }}
        slidesPerView={3}
        onActiveIndexChange={({ activeIndex }) => setActiveIndex(activeIndex)}
        breakpoints={{
          320: {
            slidesPerView: 'auto',
            spaceBetween: 20,
          },
          769: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
      >
        {fillingList.map(({ id, title, description, src }) => (
          <SwiperSlide key={`filling_item_${id}`}>
            <SwiperItemImage src={src} alt={title} />
            <SwiperItemTitle>{title}</SwiperItemTitle>
            {/* <SwiperItemDescription>{description}</SwiperItemDescription> */}
          </SwiperSlide>
        ))}
      </SwiperContainer>
      <MobilePagination>
        {fillingList.map(({ id }, index) => (
          <MobilePaginationItem
            key={`swiper_pagination_${id}`}
            isActive={activeIndex === index}
            onClick={() => setActiveIndex(index)}
          />
        ))}
      </MobilePagination>
      <ButtonsBlock>
        <NavButton onClick={onPressPrev}>
          <PrevArrowIcon />
        </NavButton>
        <NavButton onClick={onPressNext}>
          <NextArrowIcon />
        </NavButton>
      </ButtonsBlock>
    </Section>
  )
}

export default Filling

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${horizontalScale(160)} ${horizontalScale(70)};

  ${mobileMediaQuery((css, mobileScale) => css`
    padding: ${mobileScale(64)} ${mobileScale(16)};
  `)}
`

const Title = styled.h2`
  font-weight: 500;
  font-size: ${horizontalScale(48)};
  line-height: ${horizontalScale(58)};
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #98734F;
  margin-bottom: ${horizontalScale(40)};

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(18)};
    line-height: ${mobileScale(22)};
    margin-bottom: ${mobileScale(16)};
  `)}
`

const Description = styled.p`
  font-size: ${horizontalScale(24)};
  line-height: ${horizontalScale(29)};
  text-align: center;
  color: #393939;
  max-width: ${horizontalScale(445)};
  margin-top: 0;
  margin-bottom: ${horizontalScale(72)};

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(17)};
    max-width: ${mobileScale(288)};
    margin-bottom: ${mobileScale(32)};
  `)}
`

const SwiperContainer = styled(Swiper)`
  width: 100%;
  height: 100%;
  margin-bottom: ${horizontalScale(77)};

  .swiper-slide {
    height: ${horizontalScale(348)};
    position: relative;
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    max-width: 100vw;

    .swiper-slide {
      width: ${mobileScale(237)};
      height: ${mobileScale(162)};
      position: relative;
    }
  `)}
`

const MobilePagination = styled.ul`
  display: none;

  ${mobileMediaQuery((css, mobileScale) => css`
    display: flex;
    width: ${mobileScale(288)};
    height: ${mobileScale(2)};
    padding: 0;
    background-color: #8EA1B1;
  `)}
`

const MobilePaginationItem = styled.li<{ isActive: boolean }>`
  display: none;

  ${mobileMediaQuery((css) => css`
    display: flex;
    flex: 1;
    background-color: #8EA1B1;
    transition: 0.3s ease-out;

    &:active {
      opacity: 0.3;
    }
  `)}

  ${({ isActive }) => isActive && mobileMediaQuery((css) => css`background-color: #FEF4D9;`)}
`

const SwiperItemImage = styled.img`
  width: ${horizontalScale(510)};
  height: ${horizontalScale(340)};
  margin-bottom: ${horizontalScale(16)};
  box-shadow: ${horizontalScale(8)} ${horizontalScale(8)} 0px 0px #FEF4D9;

  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(237)};
    height: ${mobileScale(158)};
    object-fit: contain;
    margin-bottom: ${mobileScale(8)};
    box-shadow: ${mobileScale(4)} ${mobileScale(4)} 0px 0px #FEF4D9;
  `)}
`

const SwiperItemTitle = styled.div`
  position: absolute;
  display: flex;
  align-items: end;
  left: ${horizontalScale(30)};
  bottom: ${horizontalScale(32)};
  font-size: ${horizontalScale(24)};
  line-height: ${horizontalScale(29)};
  text-align: left;
  color: #170000;
  width: min-content;
  height: ${horizontalScale(58)};
  float: bottom;
  
  ${mobileMediaQuery((css, mobileScale) => css`
    display: flex;
    align-items: end;
    left: ${mobileScale(16)};
    bottom: ${mobileScale(16)}; 
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(17)};
    height: ${mobileScale(34)};
  `)}
`

const SwiperItemDescription = styled.p`
  left: ${horizontalScale(30)};
  bottom: ${horizontalScale(24)};
  font-size: ${horizontalScale(24)};
  line-height: ${horizontalScale(29)};
  margin: 0;
  margin-left: ${horizontalScale(24)};
  margin-right: ${horizontalScale(16)};
  text-align: left;
  color: #9B9999;

  ${mobileMediaQuery((css, mobileScale) => css`
    left: ${mobileScale(19)};
    bottom: ${mobileScale(13)}; 
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(17)};
    max-width: ${mobileScale(205)};
    margin-left: auto;
    margin-right: auto;
  `)}
`

const ButtonsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${horizontalScale(120)};
  align-self: flex-end;
  margin-right: ${horizontalScale(25)};

  ${mobileMediaQuery((css) => css`
    display: none;
  `)}
`

const NavButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:active {
    circle {
      stroke: #FEF4D9;
    }
  }
`

const NextArrowIcon = styled(NextArrowSVG)`
  width: ${horizontalScale(54)};
  height: ${horizontalScale(54)};
  stroke-width: ${horizontalScale(2)};
`

const PrevArrowIcon = styled(NextArrowSVG)`
  width: ${horizontalScale(54)};
  height: ${horizontalScale(54)};
  stroke-width: ${horizontalScale(2)};
  transform: scaleX(-1);
`
