import { useRef } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react';
import { horizontalScale, mobileMediaQuery, useIsMobile } from 'utils/helpers'
import { ReactComponent as NextArrowSVG } from 'assets/svg/next_arrow.svg'
import ARTICLES_LIST from './mockups'

const ButtonsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${horizontalScale(120)};
  align-self: flex-end;
  margin-right: ${horizontalScale(25)};

  ${mobileMediaQuery((css) => css`
    display: none;
  `)}
`

const NavButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:active {
    circle {
      stroke: #FEF4D9;
    }
  }
`

const NextArrowIcon = styled(NextArrowSVG)`
  width: ${horizontalScale(54)};
  height: ${horizontalScale(54)};
  stroke-width: ${horizontalScale(2)};
`

const PrevArrowIcon = styled(NextArrowSVG)`
  width: ${horizontalScale(54)};
  height: ${horizontalScale(54)};
  stroke-width: ${horizontalScale(2)};
  transform: scaleX(-1);
`

const Press = () => {
  const swiperRef = useRef<any>(null)

  const isMobile = useIsMobile()

  const onPressPrev = () => swiperRef.current?.slidePrev()

  const onPressNext = () => swiperRef.current?.slideNext()

  const renderSlider = () => (
    <>
      <SwiperContainer
        onSwiper={(swiper) => { swiperRef.current = swiper }}
        slidesPerView={2}
        spaceBetween={30}
      >
        {ARTICLES_LIST.map(({ id, title, previewSrc, link }) => (
          <SwiperSlide key={`press-link-${id}`}>
            <SliderItem>
              <SliderItemImage src={previewSrc} alt={title} />
              <SliderItemTitle>{title}</SliderItemTitle>
              <SliderItemLink
                href={link}
                target='_blank'
                rel='noopener noreferrer'
              >
                  Узнать больше
              </SliderItemLink>
            </SliderItem>
          </SwiperSlide>
        ))}
      </SwiperContainer>
      <ButtonsBlock>
        <NavButton onClick={onPressPrev}>
          <PrevArrowIcon />
        </NavButton>
        <NavButton onClick={onPressNext}>
          <NextArrowIcon />
        </NavButton>
      </ButtonsBlock> 
    </>
  )

  return (
    <Section id='press'>
      <Title>Пресса о нас</Title>
      {isMobile
        ? ARTICLES_LIST.map(({ id, title, previewSrc, link }) => (
            <SliderItem key={`press-link-${id}`}>
              <SliderItemImage src={previewSrc} alt={title} />
              <SliderItemTitle>{title}</SliderItemTitle>
              <SliderItemLink
                href={link}
                target='_blank'
                rel='noopener noreferrer'
              >
                  Узнать больше
              </SliderItemLink>
            </SliderItem>
          ))
        : renderSlider()}
    </Section>
  )
}

export default Press

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${horizontalScale(160)} ${horizontalScale(221)};

  ${mobileMediaQuery((css, mobileScale) => css`
    padding: ${mobileScale(64)} ${mobileScale(16)};
  `)}
`

const Title = styled.h2`
  font-weight: 500;
  font-size: ${horizontalScale(48)};
  line-height: ${horizontalScale(58)};
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #98734F;
  margin-bottom: ${horizontalScale(72)};

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(18)};
    line-height: ${mobileScale(22)};
    margin-bottom: ${mobileScale(32)};
  `)}
`

const SwiperContainer = styled(Swiper)`
  width: 100%;
  height: 100%;
  margin-bottom: ${horizontalScale(40)};

  ${mobileMediaQuery((css, mobileScale) => css`
    margin-left: ${mobileScale(23)};

    .swiper-slide {
      width: ${mobileScale(249)};
    }
  `)}
`

const SliderItem = styled.div`
  display: flex;
  flex-direction: column;
  height: ${horizontalScale(469)};

  ${mobileMediaQuery((css, mobileScale) => css`
    height: auto;
  
    &:not(:last-child) {
      margin-bottom: ${mobileScale(32)};
    }
  `)}
`

const SliderItemImage = styled.img`
  width: ${horizontalScale(724)};
  height: ${horizontalScale(340)};
  margin-bottom: ${horizontalScale(24)};
  box-shadow: ${horizontalScale(8)} ${horizontalScale(8)} 0px 0px #FEF4D9;
  object-fit: cover;

  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(288)};
    height: ${mobileScale(174)};
    margin-bottom: ${mobileScale(12)};
    box-shadow: ${mobileScale(4)} ${mobileScale(4)} 0px 0px #FEF4D9;
  `)}
`

const SliderItemTitle = styled.span`
  font-size: ${horizontalScale(24)};
  line-height: ${horizontalScale(29)};
  font-weight: 500;
  color: #170000;
  text-align: left;

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(17)};
    margin-bottom: ${mobileScale(16)};
  `)}
`

const SliderItemLink = styled.a`
  cursor: pointer;
  font-size: ${horizontalScale(20)};
  line-height: ${horizontalScale(24)};
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #170000;
  text-align: left;
  margin-top: auto;

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(12)};
    line-height: ${mobileScale(14)};
  `)}
`

// const ButtonsBlock = styled.div`
//   display: flex;
//   justify-content: space-between;
//   width: ${horizontalScale(120)};
//   align-self: flex-end;
//   margin-right: ${horizontalScale(25)};

//   ${mobileMediaQuery((css) => css`
//     display: none;
//   `)}
// `


// const NavButton = styled.button`
//   padding: 0;
//   background-color: transparent;
//   border: none;
//   cursor: pointer;
// `

// const NextArrowIcon = styled(NextArrowSVG)`
//   width: ${horizontalScale(54)};
//   height: ${horizontalScale(54)};
//   stroke-width: ${horizontalScale(2)};

//   circle {
//     stroke: #FEF4D9;
//   }
// `

// const PrevArrowIcon = styled(NextArrowSVG)`
//   width: ${horizontalScale(54)};
//   height: ${horizontalScale(54)};
//   stroke-width: ${horizontalScale(2)};
//   transform: scaleX(-1);

//   circle {
//     stroke: #FEF4D9;
//   }
// `