import styled from 'styled-components'
import { Link } from 'react-scroll'
import { horizontalScale, mobileMediaQuery } from 'utils/helpers'
import { ReactComponent as LogoSVG } from 'assets/svg/logo.svg'
import { ReactComponent as CofeemaniaSVG } from 'assets/svg/cofeemania.svg'
import { ReactComponent as InstagramSVG } from 'assets/svg/instagram.svg'
import { ReactComponent as FacebookSVG } from 'assets/svg/facebook.svg'

const Footer = () => (
  <FooterContainer>
    <FooterTop>
      <LogoBlock>
        <a href='#main'>
          <LogoIcon />
        </a>
        <a
          href='https://coffeemania.ru/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <CofeemaniaIcon />
        </a>
      </LogoBlock>
      <Navigation>
        <ul>
          <li>
            <Link to='form' spy={true} smooth={true} duration={500}>
              Оставить заявку
            </Link>
          </li>
          <li>
            <Link to='about' spy={true} smooth={true} duration={500}>
              Об ателье
            </Link>
          </li>
          <li>
            <Link to='collections' spy={true} smooth={true} duration={500}>
              Коллекции
            </Link>
          </li>
          <li>
            <Link to='decoration' spy={true} smooth={true} duration={500}>
              Начинки и декор
            </Link>
          </li>
        </ul>
        <ul>
          <li>
            <a href='/cakes' target='_blank' rel='noopener noreferrer'>
              Основной каталог
            </a>
          </li>
          <li>
            <a href='/wedding' target='_blank' rel='noopener noreferrer'>
              Свадебный каталог
            </a>
          </li>
          <li>
            <Link to='press' spy={true} smooth={true} duration={500}>
              Пресса
            </Link>
          </li>
          <li>
            <Link to='contacts' spy={true} smooth={true} duration={500}>
              Контакты
            </Link>
          </li>
          <li>
            <Link to='faq' spy={true} smooth={true} duration={500}>
              FAQ
            </Link>
          </li>
        </ul>
      </Navigation>
      {false &&
      <Socials>
        <a
          href='https://www.instagram.com/cakeburo/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <InstagramIcon />
        </a>
        <a
          href='https://www.facebook.com/cakeburo'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FacebookIcon />
        </a>
      </Socials>}
    </FooterTop>
    <FooterBottom>
      <CofeemaniaRights>
        <span>© Кофемания. 2001-{new Date().getFullYear()}. Все права защищены</span>
        <a
          href='https://coffeemania.ru/'
          target='_blank'
          rel='noopener noreferrer'
        >
          www.coffeemania.ru
        </a>
      </CofeemaniaRights>
      <RulesWrapper>
        <a
          href={`${process.env.PUBLIC_URL}/privacy-policy.pdf`}
          target='_blank'
          rel='noopener noreferrer'
        >
          Политика конфиденциальности
        </a>
        <a
          href={`${process.env.PUBLIC_URL}/Правила_заказа_авторских_тортов_Cake_Buro.pdf`}
          target='_blank'
          rel='noopener noreferrer'
        >
          Правила заказа авторских тортов
        </a>
      </RulesWrapper>
    </FooterBottom>
  </FooterContainer>
)

export default Footer

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
`

const FooterTop = styled.div`
  display: flex;
  padding: ${horizontalScale(48)} ${horizontalScale(221)} ${horizontalScale(24)} ${horizontalScale(221)};
  border-bottom: 1px solid #F3F3F3;

  ${mobileMediaQuery((css, mobileScale) => css`
    flex-direction: column;
    padding:
      ${mobileScale(32)}
      ${mobileScale(16)}
      ${mobileScale(16)}
      ${mobileScale(16)};
  `)}
`

const LogoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${horizontalScale(195)};
  margin-right: ${horizontalScale(569)};
  
  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(74)};
    margin-bottom: ${mobileScale(65)};
    margin-right: 0;
  `)}
`

const LogoIcon = styled(LogoSVG)`
  width: ${horizontalScale(91)};
  height: ${horizontalScale(90)};

  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(35)};
    height: ${mobileScale(35)};
  `)}
`

const CofeemaniaIcon = styled(CofeemaniaSVG)`
  width: ${horizontalScale(70)};
  height: ${horizontalScale(84)};

  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(26)};
    height: ${mobileScale(34)};
  `)}
`

const Navigation = styled.nav`
  display: flex;
  justify-content: space-between;
  width: ${horizontalScale(376)};

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      justify-content: flex-start;

      &:not(:last-child) {
        margin-bottom: ${horizontalScale(16)};
      }

      a {
        font-weight: 500;
        font-size: ${horizontalScale(16)};
        line-height: ${horizontalScale(19)};
        text-transform: uppercase;
        color: #9B9999;
        cursor: pointer;
      }
    }
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(288)};
    margin-bottom: ${mobileScale(64)};
    justify-content: space-start;

    ul {
      width: ${mobileScale(136)};
    }

    ul:last-child {
      margin-left: ${mobileScale(16)};
    }

    ul > li {
      &:not(:last-child) {
        margin-bottom: ${mobileScale(8)};
      }

      a {
        font-size: ${mobileScale(12)};
        line-height: ${mobileScale(14)};
        font-weight: 500;
        text-transform: capitalize;
      }
    }
  `)}
`

const Socials = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${horizontalScale(72)};
  margin-left: auto;

  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(48)};
    height: ${mobileScale(16)};
    padding-top: 0;
    margin-left: 0;
  `)}
`

const InstagramIcon = styled(InstagramSVG)`
  width: ${horizontalScale(23)};
  height: ${horizontalScale(21.91)};

  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(16)};
    height: ${mobileScale(16)};
  `)}
`

const FacebookIcon = styled(FacebookSVG)`
  width: ${horizontalScale(23)};
  height: ${horizontalScale(21.91)};

  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(16)};
    height: ${mobileScale(16)};
  `)}
`

const FooterBottom = styled.div`
  display: flex;
  padding: 0 ${horizontalScale(221)};

  ${mobileMediaQuery((css, mobileScale) => css`
    padding: ${mobileScale(16)};
    flex-direction: column;
  `)}
`

const CofeemaniaRights = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: ${horizontalScale(16)};
  width: ${horizontalScale(500)};
  padding-top: ${horizontalScale(39)};
  padding-bottom: ${horizontalScale(48)};

  span {
    font-size: ${horizontalScale(16)};
    line-height: ${horizontalScale(24)};
    color: #9B9999;
  }

  a {
    font-weight: 500;
    font-size: ${horizontalScale(16)};
    line-height: ${horizontalScale(24)};
    height: ${horizontalScale(18)};
    text-decoration-line: underline;
    color: #9B9999;
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    row-gap: ${mobileScale(8)};
    width: 100%;
    order: 2;
    padding: 0;

    span {
      font-size: ${mobileScale(12)};
      line-height: ${mobileScale(14)};
    }

    a {
      font-size: ${mobileScale(12)};
      line-height: ${mobileScale(14)};
      height: ${mobileScale(14)};
    }
  `)}
`

const RulesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: ${horizontalScale(16)};
  width: ${horizontalScale(500)};
  padding-top: ${horizontalScale(39)};
  padding-bottom: ${horizontalScale(48)};

  a {
    font-size: ${horizontalScale(16)};
    line-height: ${horizontalScale(24)};
    color: #9B9999;
    margin-left: ${horizontalScale(213)};
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    row-gap: ${mobileScale(8)};
    width: 100%;
    padding: 0;
    margin-bottom: ${mobileScale(8)};

    a {
      font-size: ${mobileScale(12)};
      line-height: ${mobileScale(14)};
      margin-left: 0;
      text-align: left;
      order: 1;
    }
  `)}
`
