import { useEffect, useState, useRef } from 'react'
import { css, ThemedCssFunction } from 'styled-components'

export const horizontalScale = (size: number, isMobile?: boolean) => {
  return `calc(100vw * (${size} / ${isMobile ? 320 : 1920}))`
}

export const mobileMediaQuery = <OwnProps extends object>(cb: (c: ThemedCssFunction<OwnProps>, scaleFunc: (size: number) => string) => ReturnType<typeof css>) => css`
  @media screen and (max-width: 768px) {
    ${cb(css, (size) => horizontalScale(size, true))}
  }
`

const MOBILE_BREAKPOINT = 768

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_BREAKPOINT)

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT)

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return isMobile
}


export const useDidUpdate: typeof useEffect = (callback, deps) => {
  const hasMount = useRef(false);

  useEffect(() => {
    if (hasMount.current) {
      callback();
    } else {
      hasMount.current = true;
    }
  }, [callback, deps]);
};