import { useState } from 'react'
import styled, { css } from 'styled-components'
import * as yup from 'yup';
import { useFormik } from 'formik';
import InputMask from 'react-input-mask'
import Modal from 'react-modal'
import { horizontalScale, mobileMediaQuery, useIsMobile } from 'utils/helpers'
import { ReactComponent as DiamondSVG } from 'assets/svg/diamond.svg'
import { ReactComponent as CancelSVG } from 'assets/svg/cancel.svg'
import CloseSVG from 'assets/svg/close-red.svg'

const FORM_INITIAL_VALUES = {
  fullName: '',
  phone: '',
  email: '',
  message: '',
  fileUrls: [],
}

const VALIDATION_SCHEMA = yup.object().shape({
  fullName: yup.string()
    .min(4, 'Минимум 4 символа')
    .max(50, 'Максимум 50 символов')
    .required('Укажите имя!'),
  phone: yup.string()
    .matches(new RegExp('^[^_]+$'), 'Телефон имеет неверный формат!')
    .required('Укажите номер телефонa!'),
  email: yup.string()
    .email('Почтовый адрес имеет неверный формат!')
    .required('Укажите email!'),
  message: yup.string(),
})

const Form = () => {
  const isMobile = useIsMobile()

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileNames, setFileNames] = useState<string[]>([]);

  const formik = useFormik({
    initialValues: FORM_INITIAL_VALUES,
    validationSchema: VALIDATION_SCHEMA,
    validateOnBlur: true,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values) => {
      try {
        const response = await fetch('https://platform.axept.com/api/channels/w2YoJLXSqxgYBWIMp5tX', {
          method: 'post',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: {
              'site': window.location.hostname,
              'full-name': values.fullName,
              'phone': values.phone,
              'email': values.email,
              'message': values.message + '\n\nПрикрепленные файлы:\n' + values.fileUrls.join('\n'),
            },
          }),
        })
        if (response.status === 200) {
          setIsModalOpen(true)
          formik.setValues({ ...FORM_INITIAL_VALUES })
          formik.setFieldValue('fileUrls', [], false)
          setFileNames([]) 
        }
      } catch(err) {

      }    
    },
  });

  const handleFileButtonClick = (event: any) => {
    event.currentTarget.querySelector('input').click()
  }

  const handleInputFileChange = (event: any) => {
    const formData = new FormData()
    const file: string = event.target.files[0]
    if (typeof file === 'undefined' || file === null || file === '') {
      console.log('File not defined or not selected')
      return
    }
    formData.append('file', file)

    const uploadFileUrl = `https://platform.axept.com/api/files/storage`

    fetch(uploadFileUrl, {
      method: 'post',
      body: formData
    }).then(response => {
      return response.json()
    }).then(json => {
      fileNames.push(json.data.name)
      setFileNames(fileNames)
      const formikFileUrls:any = formik.values.fileUrls
      formikFileUrls.push(json.data.url)
      formik.setFieldValue('fileUrls', formikFileUrls, false)
    }).catch(err => {
      console.log(err)
    })
  }

  const handlerRemoveFile = (event:any, index: number) => {
    event.preventDefault()
    setFileNames(fileNames.filter((link, i) => i !== index))
    formik.setFieldValue('fileUrls', formik.values.fileUrls.filter((link, i) => i !== index), false)
  }

  return (
    <>
      <Section id='form'>
        <TextBlock>
          <Title>оставить заявку</Title>
          <Description>
            Отправьте запрос для связи с личным менеджером
          </Description>
        </TextBlock>
        <FormWrapper onSubmit={formik.handleSubmit}>
          <InputContainer>
            <NameInput
              placeholder='Имя'
              name='fullName'
              type='text'
              onChange={formik.handleChange('fullName')}
              value={formik.values.fullName}
            />
            <ErrorMessage>{formik.errors.fullName}</ErrorMessage>
          </InputContainer>
          <InputContainer>
            <PhoneInput
              placeholder='Телефон'
              name='phone'
              type='text'
              onChange={formik.handleChange('phone')}
              value={formik.values.phone}
              maskPlaceholder='_'
              mask={formik.values.phone.length ? '+7(999)999-99-99' : ''}
            />
            <ErrorMessage>{formik.errors.phone}</ErrorMessage>
          </InputContainer>
          <InputContainer>
            <EmailInput
              placeholder='E-mail'
              name='email'
              type='text'
              onChange={formik.handleChange('email')}
              value={formik.values.email}
            />
            <ErrorMessage>{formik.errors.email}</ErrorMessage>
          </InputContainer>
          <CommentInput
            placeholder={
              isMobile
              ? 'Событие, дата, количество гостей, комментарии'
              : 'Событие, дата cобытия, количество гостей, комментарии'
            }
            name='message'
            type='textarea'
            onChange={formik.handleChange('message')}
            value={formik.values.message}
          />
          <FileUpload>
            <FileButton className='input-file' onClick={handleFileButtonClick}>
              <DiamondIcon />
              <input
                type='file'
                name='file'
                onChange={handleInputFileChange}
                accept='.pdf,.doc,.docx,.xls,.xlsx,.odt,.ods,.txt,.jpeg,.jpg,.png'
              />
              <span>{fileNames.length ? 'Добавить файл' : 'Загрузить файл'}</span>
              <DiamondIcon />
            </FileButton>
            <FileList>
            {fileNames.map((link, index) =>
              <FileListItem key={`file-item-${index}`}>
                <FileListItemName>{link}</FileListItemName>
                <FileListItemRemoveFile onClick={(event) => handlerRemoveFile(event, index)} />
              </FileListItem>
            )}
            </FileList>
          </FileUpload>
          <SubmitButton type='submit'>
            <DiamondIcon />
            <span>
              отправить запрос
            </span>
            <DiamondIcon />
          </SubmitButton>
          <AgreementLink>
            <span>Нажимая на кнопку, я соглашаюсь с </span>
            <a
              href={`${process.env.PUBLIC_URL}/Правила_заказа_авторских_тортов_Cake_Buro.pdf`}
              target='_blank'
              rel='noopener noreferrer'
            >
              правилами заказа авторских тортов
            </a>
            <span> и на </span>
            <a
              href={`${process.env.PUBLIC_URL}/privacy-policy.pdf`}
              target='_blank'
              rel='noopener noreferrer'
            >
              обработку персональных данных
            </a>
          </AgreementLink>
        </FormWrapper>
      </Section>
      <Modal
        isOpen={isModalOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setIsModalOpen(false)}
        className='form-success-modal'
        overlayClassName='form-success-modal-overlay'
      >
        <ModalContent>
          <ModalHeader>
            <ModalTitle>заявка принята</ModalTitle>
            <CancelIcon onClick={() => setIsModalOpen(false)} />
          </ModalHeader>
          <ModalDescription>
            Мы свяжемся с вами в ближайшее рабочее время. Наш график работы пн-пт  с 10:00 до 20:00.
          </ModalDescription>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Form

const Section = styled.section`
  display: flex;
  justify-content: space-between;
  background: #FFFFFF;
  padding: ${horizontalScale(160)} ${horizontalScale(221)};

  input, div, span {
    font-family: 'TT Commons';
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    flex-direction: column;
    padding: ${mobileScale(64)} ${mobileScale(16)};
  `)}
`

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h2`
  font-size: ${horizontalScale(48)};
  line-height: ${horizontalScale(58)};
  font-weight: 500;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #98734F;
  margin-top: 0;
  margin-bottom: ${horizontalScale(50)};
  
  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(18)};
    line-height: ${mobileScale(22)};
    font-weight: normal;
    margin-bottom: ${mobileScale(16)};
    text-align: left;
  `)}
`

const Description = styled.span`
  width: ${horizontalScale(350)};
  font-weight: 500;
  font-size: ${horizontalScale(25)};
  line-height: ${horizontalScale(29)};
  letter-spacing: 0.02em;
  color: #9B9999;
  text-align: left;

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(17)};
    font-weight: 500;
    margin-bottom: ${mobileScale(32)};
    width: 100%;
  `)}
`

const FormWrapper = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: ${horizontalScale(573)};

  ${mobileMediaQuery((css) => css`
    width: 100%;
  `)}
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: ${horizontalScale(100)};

  ${mobileMediaQuery((css, mobileScale) => css`
    width: 100%;
    height: ${mobileScale(68)};
  `)}
`

const ErrorMessage = styled.span`
  display: block;
  width: ${horizontalScale(272)};
  height: ${horizontalScale(37)};
  color: red;
  font-size: ${horizontalScale(18)};
  line-height: ${horizontalScale(22)};
  margin-top: ${horizontalScale(10)};

  ${mobileMediaQuery((css, mobileScale) => css`
    width: 100%;
    height: ${mobileScale(32)};
    font-size: ${mobileScale(10)};
    line-height: ${mobileScale(15)};
  `)}
`

const inputsCommonStyle = css`
  height: ${horizontalScale(63)};
  border: none;
  border-bottom: ${horizontalScale(1)} solid #505050;
  font-size: ${horizontalScale(21)};
  line-height: ${horizontalScale(28)};
  padding: ${horizontalScale(17)} 0;

  &::placeholder {
    color: #C2C7C7;
    font-size: ${horizontalScale(24)};
    line-height: ${horizontalScale(29)};
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    height: ${mobileScale(36)};
    font-size: ${mobileScale(15)};
    line-height: ${mobileScale(28)};

    &::placeholder {
      font-size: ${mobileScale(14)};
      line-height: ${mobileScale(17)};
    }
  `)}
`

const NameInput = styled.input`
  ${inputsCommonStyle}

  width: ${horizontalScale(272)};

  ${mobileMediaQuery((css) => css`
    width: 100%;
  `)}
`

const PhoneInput = styled(InputMask)`
  ${inputsCommonStyle}
  
  width: ${horizontalScale(272)};

  ${mobileMediaQuery((css) => css`
    width: 100%;
  `)}
`

const EmailInput = styled.input`
  ${inputsCommonStyle}

  width: ${horizontalScale(573)};

  ${mobileMediaQuery((css) => css`
    width: 100%;
  `)}
`

const CommentInput = styled.input`
  ${inputsCommonStyle}

  width: 100%;
  margin-bottom: ${horizontalScale(80)};

  ${mobileMediaQuery((css, mobileScale) => css`
    width: 100%;
    margin-bottom: ${mobileScale(32)};
  `)}
`

const FileUpload = styled.div`
  width: 100%;
  margin-bottom: ${horizontalScale(30)};
`

const FileButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #5D5B60;
  padding: 0 ${horizontalScale(24)};
  width: ${horizontalScale(422)};
  height: ${horizontalScale(72)};
  margin-bottom: 15px;
  background: transparent;
  text-decoration: none;
  color: #5D5B60;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: 0.3s ease-out;


  span {
    width: 100%;
    font-size: ${horizontalScale(20)};
    line-height: ${horizontalScale(24)};
    margin-top: ${horizontalScale(6)};
    font-family: 'TT Commons';
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.06em;
    text-align: center;
    text-decoration: none;
    color: #5D5B60;
    transition: 0.3s ease-out;
  }

  svg {
    transition: 0.3s ease-out;
    margin: auto 0;
  }

  &:hover {
    background: rgba(142, 161, 177, 0.3);
    border: 1px solid transparent;
  }
  
  input {
    display: none;
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    width: 100%;
    height: ${mobileScale(38)};
    padding: 0 ${mobileScale(16)};
    
    span {
      font-size: ${mobileScale(10)};
      line-height: ${mobileScale(12)};
      margin-top: ${mobileScale(3)};
      letter-spacing: 0.18em;
    }
  `)}
`

const FileList = styled.div``

const FileListItem = styled.div`
  display: flex;
`

const FileListItemName = styled.div`
  color: #5D5B60;
`

const FileListItemRemoveFile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 15px;
  height: 15px;
  margin-left: 10px;
  cursor: pointer;

  &:after {
    content: url(${CloseSVG});
    position: absolute;
    width: 15px;
    height: 15px;
    bottom: 0;
    left: 0;
  }
`

const SubmitButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #5D5B60;
  padding: 0 ${horizontalScale(24)};
  width: ${horizontalScale(422)};
  height: ${horizontalScale(72)};
  margin-bottom: ${horizontalScale(30)};
  background: transparent;
  text-decoration: none;
  color: #5D5B60;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: 0.3s ease-out;

  
  span {
    font-size: ${horizontalScale(20)};
    line-height: ${horizontalScale(24)};
    margin-top: ${horizontalScale(6)};
    font-family: 'TT Commons';
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.06em;
    text-align: center;
    text-decoration: none;
    color: #5D5B60;
    transition: 0.3s ease-out;
  }

  svg {
    transition: 0.3s ease-out;
    margin: auto 0;
  }

  &:hover {
    background: rgba(142, 161, 177, 0.3);
    border: 1px solid transparent;
  }
  
  &:active {
    border: 1px solid transparent;
    background: #8EA1B1;

    span {
      color: #FFFFFF;
    }

    svg rect {
      fill: #FFFFFF;
    }
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(288)};
    height: ${mobileScale(38)};
    padding: 0 ${mobileScale(16)};
    margin-bottom: ${mobileScale(16)};
    
    span {
      font-size: ${mobileScale(10)};
      line-height: ${mobileScale(12)};
      margin-top: ${mobileScale(3)};
      letter-spacing: 0.18em;
    }
  `)}
`

const DiamondIcon = styled(DiamondSVG)`
  width: ${horizontalScale(16)};
  height: ${horizontalScale(16)};

  rect {
    fill: #5D5B60;
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(8)};
    height: ${mobileScale(8)};
  `)}
`

const AgreementLink = styled.span`
  font-size: ${horizontalScale(16)};
  line-height: ${horizontalScale(20)};
  color: #D1D8D8;

  a {
    color: #CFAF4E;
    text-decoration: underline;
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(12)};
    line-height: ${mobileScale(14)};
    text-align: left;
  `)}
`

const ModalContent = styled.div`
  width: 100%;

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(17)};
    text-align: left;
  `)}
`

const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${horizontalScale(32)};
  border-bottom: ${horizontalScale(2)} solid rgba(75, 75, 75, 0.2);

  ${mobileMediaQuery((css, mobileScale) => css`
    min-height: ${mobileScale(55)};
    padding: 0 ${mobileScale(16)};
    border-bottom: ${mobileScale(0.5)} solid rgba(75, 75, 75, 0.2);
  `)}
`

const ModalDescription = styled.p`
  width: 100%;
  height: 100%;
  color: #9B9999;
  margin: 0;
  font-size: ${horizontalScale(22)};
  line-height: ${horizontalScale(32)};
  padding: ${horizontalScale(32)};

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(12)};
    line-height: ${mobileScale(18)};
    padding: ${mobileScale(35)} ${mobileScale(46)} ${mobileScale(25)} ${mobileScale(16)};
  `)}
`

const ModalTitle = styled.h3`
  font-size: ${horizontalScale(28)};
  line-height: ${horizontalScale(32)};
  max-width: 70%;
  font-weight: 500;
  text-transform: uppercase;
  color: #98734F;
  margin: 0;
  
  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(17)};
    margin-top: ${mobileScale(25)};
    text-align: left;
  `)}
`


const CancelIcon = styled(CancelSVG)`
  width: ${horizontalScale(24)};
  height: ${horizontalScale(24)};
  cursor: pointer;
  
  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(13)};
    height: ${mobileScale(13)};
  `)}
`
