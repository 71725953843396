import React from 'react'
import order_svg_1 from 'assets/svg/order_1.svg'
import order_svg_2 from 'assets/svg/order_2.svg'
import order_svg_3 from 'assets/svg/order_3.svg'
import order_svg_4 from 'assets/svg/order_4.svg'
import order_svg_5 from 'assets/svg/order_5.svg'

const ORDER_LIST = [
  {
    id: '1',
    tooltip_title: 'Позвонить в ателье или оставить заявку на сайте',
    tooltip_description: (<p>Свяжемся с вами любым удобным способом</p>),
    icon_src: order_svg_1,
  },
  {
    id: '2',
    tooltip_title: 'Обсудить торт с менеджером',
    tooltip_description: (
      <p>
        С готовностью выслушаем ваши идеи, а если не захотите ничего придумывать — предложим свои
      </p>
    ),
    icon_src: order_svg_2,
  },
  {
    id: '3',
    tooltip_title: 'Выбрать начинку',
    tooltip_description: (
      <p>
        Поможем определиться с вкусом и весом торта
      </p>
    ),
    icon_src: order_svg_4,
  },
  {
    id: '4',
    tooltip_title: 'Утвердить эскиз',
    tooltip_description: (
      <p>
        Торт будет точь-в-точь, как на картинке
      </p>
    ),
    icon_src: order_svg_3,
  },
  {
    id: '5',
    tooltip_title: 'Получить готовый торт',
    tooltip_description: (
      <p>
        Привезём в удобный ресторан или к месту мероприятия
      </p>
    ),
    icon_src: order_svg_5,
  },
]

export default ORDER_LIST
