import styled from 'styled-components'
// @ts-ignore
import { isWebpSupported } from 'react-image-webp/dist/utils'
import { horizontalScale, mobileMediaQuery } from 'utils/helpers'
import teamImgJpg from 'assets/images/team.jpg'
import teamImgWebp from 'assets/images/team.webp'
import teamMobileImgJpg from 'assets/images/team-mobile.jpg'
import teamMobileImgWebp from 'assets/images/team-mobile.webp'

const teamImg = isWebpSupported() ? teamImgWebp : teamImgJpg
const teamMobileImg = isWebpSupported() ? teamMobileImgWebp : teamMobileImgJpg

const Team = () => (
  <Section id='team'>
    <TeamPhoto />
    <div>
      <Title>О команде </Title>
      <PreDescription>
        Cake Buro — это ателье тортов для особого случая от ресторанов «Кофемания».
      </PreDescription>
    </div>
    <Description>
      Над каждым проектом работает целая команда профессионалов: кондитеров, скульпторов, флористов и декораторов. Имея уникальный опыт в создании праздничных тортов, они безупречно воплощают в жизнь любые идеи и замысел.
      <br />
      <br />
      Менеджеры Cake Buro чутко следят за мировыми трендами в кондитерской индустрии, чтобы подобрать самые актуальные и интересные решения для ваших тортов. Мы используем отборные ингредиенты, но главные из них — уважение, забота и любовь к нашим гостям.
    </Description>
  </Section>
)

export default Team

const Section = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #FEF4D9;
  padding: ${horizontalScale(160)} ${horizontalScale(221)};

  ${mobileMediaQuery((css, mobileScale) => css`
    padding: ${mobileScale(64)} ${mobileScale(16)};
    height: auto;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
  `)}
`

const TeamPhoto = styled.div`
  width: ${horizontalScale(1478)};
  height: ${horizontalScale(800)};
  background-image: url(${teamImg});
  background-size: cover;
  
  ${mobileMediaQuery((css, mobileScale) => css`
    background-image: url(${teamMobileImg});
    width: ${mobileScale(291)};
    height: ${mobileScale(200)};
    margin-bottom: ${mobileScale(32)};
  `)}
`

const Title = styled.h2`
  font-weight: 500;
  font-size: ${horizontalScale(48)};
  line-height:${horizontalScale(58)};
  margin-top: ${horizontalScale(68)};
  margin-bottom: ${horizontalScale(40)};
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #98734F;

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(18)};
    line-height:${mobileScale(22)};
    margin-bottom: ${mobileScale(16)};
    margin-top: 0;
    text-align: left;
  `)}
`

const PreDescription = styled.p`
  max-width: ${horizontalScale(560)};
  text-align: left;
  font-weight: 500;
  font-size: ${horizontalScale(32)};
  line-height:${horizontalScale(38)};
  color: #170000;
  margin-bottom: ${horizontalScale(36)};

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height:${mobileScale(17)};
    max-width: ${mobileScale(288)};
    margin-bottom: ${mobileScale(24)};
  `)}
`

const Description = styled.p`
  max-width: ${horizontalScale(724)};
  text-align: left;
  font-weight: 500;
  font-size: ${horizontalScale(24)};
  line-height:${horizontalScale(29)};
  color: #9B9999;
  margin-top: ${horizontalScale(68)};
  margin-bottom: 0;

  br {
    content: '' !important;
    display: block !important;
    margin-bottom: ${horizontalScale(17)} !important;
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height:${mobileScale(17)};
    max-width: ${mobileScale(288)};
    margin-top: 0;
    font-weight: normal;
  `)}
`