import advantages_svg_1 from 'assets/svg/advantages_1.svg'
import advantages_svg_2 from 'assets/svg/advantages_2.svg'
import advantages_svg_3 from 'assets/svg/advantages_3.svg'
import advantages_svg_4 from 'assets/svg/advantages_4.svg'
import advantages_svg_5 from 'assets/svg/advantages_5.svg'

const ADVANTAGES_LIST = [
  {
    id: '1',
    title: 'Безупречное воплощение',
    description: 'Наши кондитеры с ювелирной точностью воссоздают торты по вашим предпочтениям и референсам',
    icon_src: advantages_svg_3,
  },
  {
    id: '2',
    title: 'Чуткий сервис',
    description: 'Помогаем разобраться в начинках, определиться с весом и выбрать дизайн будущего торта',
    icon_src: advantages_svg_4,
  },
  {
    id: '3',
    title: 'Исключительное мастерство',
    description: 'Кондитеры Cake Buro ежедневно реализуют десятки трудоёмких проектов: от точной копии Эйфелевой башни до букета съедобных пионов, которые не отличить от настоящих',
    icon_src: advantages_svg_2,
  },
  {
    id: '4',
    title: 'Бережная доставка',
    description: 'Используем для транспортировки тортов машины с холодильным оборудованием, чтобы довезти их до места торжества в первозданном виде',
    icon_src: advantages_svg_5,
  },
  {
    id: '5',
    title: 'Индивидуальный подход',
    description: 'Предлагаем варианты стилизации торта в соответствии с цветовым решением и концепцией вашего праздника',
    icon_src: advantages_svg_1,
  },
]

export default ADVANTAGES_LIST
